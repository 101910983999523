import { Stage, Status } from "../services/config/enum"
import TeamsDocument from "./teamsDocument"

export enum SurveyProvider {
  typeform = "typeform",
  qualtrics = "qualtrics"
}

interface SurveyDocumentItem {
  isDefault: boolean
  lang: string
  title: string
  type: string
  parentId?: string
  formId: string
  formUrl: string
  webhookUrl?: string // Optional since it's generated on the server
  webhookSecret?: string // Optional since it's generated on the server
  updatedAt?: string
  body?: any[]
}

interface SurveyDocument {
  parentId: string
  items: SurveyDocumentItem[]
}

interface Survey {
  id: string
  provider: SurveyProvider
  stage: Stage
  status: Status
  handle: string
  teamId: string
  team: { id: string; document: { items: TeamsDocument[] } }
  projectId: string
  suspended?: boolean
  useSecret?: boolean
  storeResponses?: boolean
  hasSibling: boolean
  document: SurveyDocument
  createdAt: string
  updatedAt: string
  startsAt: string
  endsAt: string
  createdAtT: number
  updatedAtT: number
  startsAtT: number
  endsAtT: number
  lastUpdatedAt: string
}

export default Survey
