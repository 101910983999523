export enum MediaSize {
  xs = "xs",
  s = "s",
  m = "m",
  l = "l",
  xl = "xl",
  xxl = "xxl",
}

export enum Esg {
  environment = "environment",
  social = "social",
  governance = "governance",
}

export enum Footprint {
  home = "home",
  transport = "transport",
  lifestyle = "lifestyle",
  food = "food",
}

export enum BodySliceType {
  BodyTextSlice = "BodyTextSlice",
  BodyImageSlice = "BodyImageSlice",
  BodyQuoteSlice = "BodyQuoteSlice",
  BodyEmbedSlice = "BodyEmbedSlice",
}

export enum BadgeType {
  ActionGroup = "ActionGroup",
}

export enum ChallengeType {
  featured = "featured",
  reserved = "reserved",
  reservedFeatured = "reservedFeatured",
}

export enum JobStatus {
  PENDING = "PENDING",
  RUNNING = "RUNNING",
  COMPLETED = "COMPLETED",
  ERROR = "ERROR",
}

export enum TeamAccessType {
  public = "public",
  activationCode = "activationCode",
  sso = "sso",
}

export enum Stage {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  EDITED = "EDITED",
  ARCHIVED = "ARCHIVED",
}

export enum Status {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
}

export enum TeamStage {
  backstage = "backstage",
  hidden = "hidden",
  live = "live",
  featured = "featured",
}

export enum TeamStakeholderType {
  customers = "customers",
  citizens = "citizens",
  employees = "employees",
  students = "students",
  fans = "fans",
  general = "general",
}

export enum TeamOrganizationType {
  corporate = "corporate",
  company = "company",
  publicSector = "publicSector",
  education = "education",
  eventOrganizer = "eventOrganizer",
  media = "media",
  unknown = "unknown",
}

export enum TeamVisibilityOptionType {
  everyone = "everyone",
  members = "members",
  none = "none",
}

export enum ActivationCodeType {
  PlatformActivationCode = "PlatformActivationCode",
  TeamActivationCode = "TeamActivationCode",
  ChallengeActivationCode = "ChallengeActivationCode",
  WrapperActivationCode = "WrapperActivationCode",
  NftActivationCode = "NftActivationCode",
}

export enum ActivationCodePolicy {
  IterationLimited = "IterationLimited",
  IterationUnlimited = "IterationUnlimited",
}

export enum ActionType {
  Standard = "Standard",
  Autolog = "Autolog",
}

export enum ProductAvailability {
  Limited = "Limited",
  Unlimited = "Unlimited",
}

export enum ProductType {
  DigitalCode = "DigitalCode",
  Gamification = "Gamification",
}

export enum GamificationProductType {
  life = "life",
  freeze = "freeze",
}

export enum NftCatalogStatus {
  IDLE = "IDLE",
  PENDING = "PENDING",
  MINTED = "MINTED",
}

export enum NotificationTemplateType {
  EMAIL = "EMAIL",
  PUSH = "PUSH",
  IN_APP = "IN_APP",
}

export enum NotificationTemplateFormat {
  SIMPLE = "SIMPLE",
  MJML = "MJML",
}

export enum NotificationTemplateValidationStatus {
  NOT_CHECKED = "NOT_CHECKED",
  INVALID = "INVALID",
  VALID = "VALID",
}

export enum NftActivationCodeType {
  Generic = "Generic",
  CarbonFootprintStation = "CarbonFootprintStation",
}

export enum MissionType {
  episode = "episode",
  action = "action",
  quiz = "quiz",
  activity = "activity",
  streak = "streak",
  exp = "exp",
  mobility = "mobility",
}

export enum MissionSpecialType {
  onboarding = "onboarding",
}

export enum EpisodeSpecialType {
  onboarding = "onboarding",
}

export enum MissionDifficultyLevel {
  easy = "easy",
  medium = "medium",
  hard = "hard",
  champion = "champion",
}

export enum MissionTimeframe {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
}

export enum LeagueType {
  TierLeague = "TierLeague",
  LobbyLeague = "LobbyLeague",
}

export enum ChallengeVersionType {
  metricLeaderboardChallenge = "metricLeaderboardChallenge",
  pointsLeaderboardChallenge = "pointsLeaderboardChallenge",
  hybridLeaderboardChallenge = "hybridLeaderboardChallenge",
}

export enum ProductVisibility {
  AllUsers = "AllUsers",
  NewUsersOnly = "NewUsersOnly",
  TeamOnly = "TeamOnly",
  Hidden = "Hidden",
}
