import { gql } from "@apollo/client"

export const stagedEpisodes = gql`
  query StagedEpisodes(
    $lang: Lang
    $title: String
    $topic: String
    $sdg: String
    $sdgTarget: String
    $category: String
    $esg: Esg
    $doNotRecommend: Boolean
    $type: EpisodeType
    $limit: Int
    $nextToken: String
  ) {
    stagedEpisodes(
      lang: $lang
      title: $title
      topic: $topic
      sdg: $sdg
      sdgTarget: $sdgTarget
      category: $category
      esg: $esg
      doNotRecommend: $doNotRecommend
      type: $type
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        updatedAt
        createdAt
        lang
        status
        stage
        title
        image
        hasSibling
        esg
        points
        doNotRecommend
        type
        specialType
        category {
          id
          name
          foreColor
          backColor
        }
        publishedLangs {
          lang
          title
        }
        draftedLangs {
          lang
          title
        }
        topics {
          primary
          topic {
            id
            name
          }
        }
      }
      nextToken
    }
  }
`

export const archivedEpisodes = gql`
  query ArchivedEpisodes(
    $lang: Lang
    $title: String
    $topic: String
    $sdg: String
    $sdgTarget: String
    $category: String
    $esg: Esg
    $limit: Int
    $nextToken: String
  ) {
    archivedEpisodes(
      lang: $lang
      title: $title
      topic: $topic
      sdg: $sdg
      sdgTarget: $sdgTarget
      category: $category
      esg: $esg
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        updatedAt
        createdAt
        lang
        status
        stage
        title
        image
        hasSibling
        category {
          id
          name
          foreColor
          backColor
        }
        publishedLangs {
          lang
          title
        }
        draftedLangs {
          lang
          title
        }
      }
      nextToken
    }
  }
`

export const stagedEpisode = gql`
  query StagedEpisode($stagedEpisodeId: ID!) {
    stagedEpisode(id: $stagedEpisodeId) {
      id
      lang
      status
      stage
      title
      text
      image
      createdAt
      updatedAt
      esg
      doNotRecommend
      type
      specialType
      recommendStartsAt
      recommendEndsAt
      category {
        id
        name
      }
      topics {
        primary
        topic {
          id
          createdAt
          updatedAt
          createdAtT
          updatedAtT
          name
          translations {
            lang
            name
          }
        }
      }
      sdgs {
        primary
        sdg {
          id
          createdAt
          updatedAt
          createdAtT
          updatedAtT
          name
          image
          translations {
            lang
            image
            name
          }
        }
      }
      sdgTargets {
        primary
        sdgTarget {
          id
          translations {
            lang
            description
          }
        }
      }
      translations {
        id
        lang
        stage
        title
        createdAt
        updatedAt
        slides {
          slideType
          textColor
          background
          decor
          box {
            boxShape
            boxBackColor
          }
          ... on EmbedSlide {
            id
            slideType
            background
            decor
            box {
              boxShape
              boxBackColor
            }
            textColor
            embedSlideText: text
            embedSlideCaption: caption
            embedSlideCaptionTitle: captionTitle
            embedUrl
          }
          ... on FullImageSlide {
            id
            slideType
            background
            decor
            box {
              boxShape
              boxBackColor
            }
            textColor
            FullImageSlideImage: image {
              type
              url
              description
            }
            caption
            captionTitle
          }
          ... on FullTextSlide {
            id
            slideType
            textColor
            background
            decor
            box {
              boxShape
              boxBackColor
            }
            FullTextSlideText: text
          }
          ... on PortraitSlide {
            id
            slideType
            background
            decor
            box {
              boxShape
              boxBackColor
            }
            textColor
            PortraitSlideImage: image {
              type
              url
              description
            }
            PortraitSlideText: text
          }
          ... on QuizSlide {
            id
            slideType
            background
            decor
            box {
              boxShape
              boxBackColor
            }
            textColor
            quizType
            question
            opt1 {
              text
              image
            }
            opt2 {
              text
              image
            }
            opt3 {
              text
              image
            }
            opt4 {
              text
              image
            }
            correctAnswer
          }
          ... on QuoteSlide {
            id
            slideType
            background
            decor
            box {
              boxShape
              boxBackColor
            }
            textColor
            QuoteSlideImage: image {
              type
              url
              description
            }
            author
            authorTitle
            text
          }
          ... on TextImageSlide {
            id
            slideType
            textColor
            background
            decor
            box {
              boxShape
              boxBackColor
            }
            SlideImage: image {
              type
              url
              description
            }
            text
          }
          ... on TitleSlide {
            id
            slideType
            textColor
            background
            decor
            tagBox {
              tagBoxBackColor
              tagBoxTextColor
              tagBoxText
            }
            box {
              boxShape
              boxBackColor
            }
            title
            description
            image {
              type
              url
              description
            }
          }
        }
        quiz {
          id
          slideType
          background
          decor
          box {
            boxShape
            boxBackColor
          }
          textColor
          quizType
          question
          opt1 {
            text
            image
          }
          opt2 {
            text
            image
          }
          opt3 {
            text
            image
          }
          opt4 {
            text
            image
          }
          correctAnswer
        }
      }
    }
  }
`

export const episode = gql`
  query Episode($episodeId: ID!, $lang: LangInput!) {
    episode(id: $episodeId, lang: $lang) {
      id
      lang
      status
      stage
      title
      text
      image
      createdAt
      updatedAt
      esg
      doNotRecommend
      type
      specialType
      recommendStartsAt
      recommendEndsAt
      category {
        id
        name
      }
      topics {
        primary
        topic {
          id
          createdAt
          updatedAt
          createdAtT
          updatedAtT
          name
          translations {
            lang
            name
          }
        }
      }
      sdgs {
        primary
        sdg {
          id
          createdAt
          updatedAt
          createdAtT
          updatedAtT
          name
          image
          translations {
            lang
            image
            name
          }
        }
      }
      sdgTargets {
        primary
        sdgTarget {
          id
          translations {
            lang
            description
          }
        }
      }
      translations {
        id
        lang
        stage
        title
        createdAt
        updatedAt
        slides {
          slideType
          textColor
          background
          decor
          box {
            boxShape
            boxBackColor
          }
          ... on EmbedSlide {
            id
            slideType
            background
            decor
            box {
              boxShape
              boxBackColor
            }
            textColor
            embedSlideText: text
            embedSlideCaption: caption
            embedSlideCaptionTitle: captionTitle
            embedUrl
          }
          ... on FullImageSlide {
            id
            slideType
            background
            decor
            box {
              boxShape
              boxBackColor
            }
            textColor
            FullImageSlideImage: image {
              type
              url
              description
            }
            caption
            captionTitle
          }
          ... on FullTextSlide {
            id
            slideType
            textColor
            background
            decor
            box {
              boxShape
              boxBackColor
            }
            FullTextSlideText: text
          }
          ... on PortraitSlide {
            id
            slideType
            background
            decor
            box {
              boxShape
              boxBackColor
            }
            textColor
            PortraitSlideImage: image {
              type
              url
              description
            }
            PortraitSlideText: text
          }
          ... on QuizSlide {
            id
            slideType
            background
            decor
            box {
              boxShape
              boxBackColor
            }
            textColor
            quizType
            question
            opt1 {
              text
              image
            }
            opt2 {
              text
              image
            }
            opt3 {
              text
              image
            }
            opt4 {
              text
              image
            }
            correctAnswer
          }
          ... on QuoteSlide {
            id
            slideType
            background
            decor
            box {
              boxShape
              boxBackColor
            }
            textColor
            QuoteSlideImage: image {
              type
              url
              description
            }
            author
            authorTitle
            text
          }
          ... on TextImageSlide {
            id
            slideType
            textColor
            background
            decor
            box {
              boxShape
              boxBackColor
            }
            SlideImage: image {
              type
              url
              description
            }
            text
          }
          ... on TitleSlide {
            id
            slideType
            textColor
            background
            decor
            tagBox {
              tagBoxBackColor
              tagBoxTextColor
              tagBoxText
            }
            box {
              boxShape
              boxBackColor
            }
            title
            description
            image {
              type
              url
              description
            }
          }
        }
        quiz {
          id
          slideType
          background
          decor
          box {
            boxShape
            boxBackColor
          }
          textColor
          quizType
          question
          opt1 {
            text
            image
          }
          opt2 {
            text
            image
          }
          opt3 {
            text
            image
          }
          opt4 {
            text
            image
          }
          correctAnswer
        }
      }
    }
  }
`

export const listTopics = gql`
  query TopicsList($input: ListTopicsInput!) {
    topicsList(input: $input) {
      items {
        id
        name
        code
        mainTopic {
          id
          name
        }
        translations {
          name
          lang
        }
        updatedAt
      }
    }
  }
`

export const listMainTopics = gql`
  query MainTopicsList($input: ListMainTopicsInput!) {
    mainTopicsList(input: $input) {
      items {
        id
        code
        name
      }
    }
  }
`

export const listSdgs = gql`
  query SdgsList($input: ListSdgsInput!) {
    sdgsList(input: $input) {
      items {
        id
        name
        image
        code
        updatedAt
        translations {
          image
          lang
          name
        }
      }
    }
  }
`

export const listSdgTargets = gql`
  query SdgTargetsList($input: ListSdgTargetsInput!) {
    sdgTargetsList(input: $input) {
      items {
        id
        code
        updatedAt
        translations {
          lang
          description
        }
      }
    }
  }
`

export const slideConstraints = gql`
  query GetSlideConstraints {
    getSlideConstraints {
      TitleSlide {
        box {
          boxShape
        }
        title {
          minSize
          maxSize
          bestSize
        }
        word {
          minSize
          maxSize
          bestSize
        }
        description {
          minSize
          maxSize
          bestSize
        }
        decor {
          type
          width
          height
        }
        image {
          type
          width
          height
        }
      }
      FullTextSlide {
        box {
          boxShape
        }
        text {
          minSize
          maxSize
          bestSize
        }
        decor {
          type
          width
          height
        }
      }
      TextImageSlide {
        box {
          boxShape
        }
        text {
          minSize
          maxSize
          bestSize
        }
        image {
          type
          width
          height
        }
      }
      PortraitSlide {
        box {
          boxShape
        }
        text {
          minSize
          maxSize
          bestSize
        }
        image {
          type
          width
          height
        }
      }
      QuoteSlide {
        box {
          boxShape
        }
        decor {
          type
          width
          height
        }
        image {
          type
          width
          height
        }
        text {
          minSize
          maxSize
          bestSize
        }
        author {
          minSize
          maxSize
          bestSize
        }
        authorTitle {
          minSize
          maxSize
          bestSize
        }
      }
      FullImageSlide {
        box {
          boxShape
        }
        image {
          type
          width
          height
        }
        caption {
          minSize
          maxSize
          bestSize
        }
        captionTitle {
          minSize
          maxSize
          bestSize
        }
      }
      EmbedSlide {
        box {
          boxShape
        }
        text {
          minSize
          maxSize
          bestSize
        }
        caption {
          minSize
          maxSize
          bestSize
        }
        captionTitle {
          minSize
          maxSize
          bestSize
        }
      }
    }
  }
`

export const listMedia = gql`
  query MediaList($input: ListMediaInput) {
    mediaList(input: $input) {
      items {
        id
        mediaTags
        url
        title
        fileType
        description
        size
        category
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export const searchMedia = gql`
  query MediaSearch($input: SearchMediaInput!) {
    mediaSearch(input: $input) {
      items {
        id
        mediaTags
        url
        title
        fileType
        description
        size
        category
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export const getMediaUploadUrl = gql`
  query MediaUploadUrlGet($input: GetMediaUploadUrlInput!) {
    mediaUploadUrlGet(input: $input) {
      id
      url
      s3Url
      title
      description
      mediaTags
      size
      category
      createdAt
      updatedAt
    }
  }
`

export const stagedJourneys = gql`
  query StagedJourneys(
    $lang: Lang
    $title: String
    $topic: String
    $sdg: String
    $sdgTarget: String
    $category: String
    $esg: Esg
    $limit: Int
    $nextToken: String
    $journeyType: [JourneyType]
  ) {
    stagedJourneys(
      lang: $lang
      title: $title
      topic: $topic
      sdg: $sdg
      sdgTarget: $sdgTarget
      category: $category
      esg: $esg
      limit: $limit
      nextToken: $nextToken
      journeyType: $journeyType
    ) {
      items {
        id
        lang
        status
        stage
        title
        text
        image
        type
        esg
        points
        category {
          id
          name
          foreColor
          backColor
        }
        topics {
          primary
          topic {
            id
            name
          }
        }
        episodes {
          sorting
          episode {
            id
          }
        }
        publishedLangs {
          lang
          title
        }
        hasSibling
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export const archivedJourneys = gql`
  query ArchivedJourneys(
    $lang: Lang
    $title: String
    $topic: String
    $sdg: String
    $sdgTarget: String
    $category: String
    $esg: Esg
    $limit: Int
    $nextToken: String
    $journeyType: [JourneyType]
  ) {
    archivedJourneys(
      lang: $lang
      title: $title
      topic: $topic
      sdg: $sdg
      sdgTarget: $sdgTarget
      category: $category
      esg: $esg
      limit: $limit
      nextToken: $nextToken
      journeyType: $journeyType
    ) {
      items {
        id
        lang
        status
        stage
        title
        text
        image
        category {
          id
          name
          foreColor
          backColor
        }
        episodes {
          sorting
          episode {
            id
          }
        }
        publishedLangs {
          lang
          title
        }
        hasSibling
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export const stagedJourney = gql`
  query StagedJourney($stagedJourneyId: ID!) {
    stagedJourney(id: $stagedJourneyId) {
      id
      lang
      status
      stage
      title
      text
      image
      type
      esg
      category {
        id
        name
      }
      topics {
        primary
        topic {
          createdAt
          id
          name
          translations {
            lang
            name
          }
          updatedAt
        }
      }
      sdgs {
        primary
        sdg {
          id
          name
          image
        }
      }
      sdgTargets {
        primary
        sdgTarget {
          id
          translations {
            lang
            description
          }
        }
      }
      episodes {
        sorting
        episode {
          id
          lang
          title
          createdAt
          hasSibling
          stage
          status
          topics {
            primary
            topic {
              createdAt
              updatedAt
              id
              name
              translations {
                lang
                name
              }
            }
          }
          sdgs {
            primary
            sdg {
              id
              name
              image
            }
          }
          sdgTargets {
            primary
            sdgTarget {
              id
              translations {
                lang
                description
              }
            }
          }
          text
          image
          translations {
            id
            lang
            stage
            title
            createdAt
            updatedAt
          }
          updatedAt
          publishedLangs {
            lang
            title
          }
          draftedLangs {
            lang
            title
          }
        }
      }
      publishedLangs {
        lang
        title
      }
      hasSibling
      createdAt
      updatedAt
    }
  }
`

export const journey = gql`
  query Journey($journeyId: ID!) {
    journey(id: $journeyId) {
      id
      lang
      status
      stage
      title
      text
      image
      type
      esg
      category {
        id
        name
      }
      topics {
        primary
        topic {
          createdAt
          id
          name
          translations {
            lang
            name
          }
          updatedAt
        }
      }
      sdgs {
        primary
        sdg {
          id
          name
          image
        }
      }
      sdgTargets {
        primary
        sdgTarget {
          id
          translations {
            lang
            description
          }
        }
      }
      episodes {
        sorting
        episode {
          id
          lang
          title
          createdAt
          hasSibling
          stage
          status
          topics {
            primary
            topic {
              createdAt
              updatedAt
              id
              name
              translations {
                lang
                name
              }
            }
          }
          sdgs {
            primary
            sdg {
              id
              name
              image
            }
          }
          sdgTargets {
            primary
            sdgTarget {
              id
              translations {
                lang
                description
              }
            }
          }
          text
          image
          translations {
            id
            lang
            stage
            title
            createdAt
            updatedAt
          }
          updatedAt
          publishedLangs {
            lang
            title
          }
          draftedLangs {
            lang
            title
          }
        }
      }
      publishedLangs {
        lang
        title
      }
      hasSibling
      createdAt
      updatedAt
    }
  }
`

export const teamJourneys = gql`
  query TeamJourneys($teamId: ID!) {
    teamJourneys(teamId: $teamId) {
      items {
        journey {
          id
          lang
          status
          stage
          title
          text
          image
          episodes {
            sorting
            episode {
              id
            }
          }
          publishedLangs {
            lang
            title
          }
          hasSibling
        }
        sorting
      }
    }
  }
`

export const listLanguages = gql`
  query {
    __type(name: "Lang") {
      name
      enumValues {
        name
      }
    }
  }
`

export const listDailyEpisode = gql`
  query ListDailyEpisode($from: String!, $to: String!) {
    listDailyEpisode(from: $from, to: $to) {
      items {
        sk
        episode {
          id
          image
          title
        }
      }
    }
  }
`

export const listMediaTags = gql`
  query MediaTagsList {
    mediaTagsList
  }
`

export const episodeJourneys = gql`
  query EpisodeJourneys($episodeJourneysId: ID!) {
    episodeJourneys(id: $episodeJourneysId) {
      items {
        id
        stage
        image
        title
        type
      }
    }
  }
`

export const listChannels = gql`
  query ListChannels($nextToken: String) {
    listChannels(nextToken: $nextToken) {
      items {
        id
        teamId
        projectId
        type
        oldTeamId
        issuer
        clientCredentialId
        createdAt
        webhookUrl
        updatedAt
      }
      nextToken
    }
  }
`

export const channel = gql`
  query Channel($channelId: String) {
    channel(id: $channelId) {
      id
      teamId
      projectId
      type
      oldTeamId
      issuer
      clientCredentialId
      createdAt
      webhookUrl
      reportUrl
      privacyVersion
      updatedAt
    }
  }
`

export const channelJourneys = gql`
  query ChannelJourneys($channelId: ID!, $lang: Lang!) {
    channelJourneys(channelId: $channelId, lang: $lang) {
      items {
        startsAt
        endsAt
        journey {
          id
          lang
          status
          stage
          title
          text
          image
          publishedLangs {
            lang
            title
          }
          hasSibling
          createdAt
          updatedAt
        }
      }
    }
  }
`

export const allNewsList = gql`
  query NewsAllList($input: NewsAllListInput!) {
    newsAllList(input: $input) {
      items {
        document {
          items {
            body {
              ... on BodyEmbedSlice {
                sliceType
                embedUrl
                embedText
                embedCaption
                embedCaptionTitle
              }
              ... on BodyImageSlice {
                sliceType
                imgUrl
                imgCaption
                imgCaptionTitle
              }
              ... on BodyQuoteSlice {
                sliceType
                quoteText
                author
                authorTitle
              }
              ... on BodyTextSlice {
                sliceType
                text
              }
            }
            isDefault: default
            image
            lang
            parentId
            redirectUrl
            tagline
            title
            type
            updatedAt
          }
        }
        endsAt
        id
        startsAt
        teamId
        updatedAt
        points
      }
      nextToken
    }
  }
`

export const searchNews = gql`
  query NewsSearch($input: NewsSearchInput!) {
    newsSearch(input: $input) {
      items {
        document {
          items {
            body {
              ... on BodyEmbedSlice {
                sliceType
                embedUrl
                embedText
                embedCaption
                embedCaptionTitle
              }
              ... on BodyImageSlice {
                sliceType
                imgUrl
                imgCaption
                imgCaptionTitle
              }
              ... on BodyQuoteSlice {
                sliceType
                quoteText
                author
                authorTitle
              }
              ... on BodyTextSlice {
                sliceType
                text
              }
            }
            isDefault: default
            image
            lang
            parentId
            redirectUrl
            tagline
            title
            type
            updatedAt
          }
        }
        endsAt
        id
        startsAt
        teamId
        updatedAt
        points
      }
      nextToken
    }
  }
`

export const allCurrentNewsList = gql`
  query NewsLiveList($input: NewsLiveListInput!) {
    newsLiveList(input: $input) {
      items {
        document {
          items {
            body {
              ... on BodyEmbedSlice {
                sliceType
                embedUrl
                embedText
                embedCaption
                embedCaptionTitle
              }
              ... on BodyImageSlice {
                sliceType
                imgUrl
                imgCaption
                imgCaptionTitle
              }
              ... on BodyQuoteSlice {
                sliceType
                quoteText
                author
                authorTitle
              }
              ... on BodyTextSlice {
                sliceType
                text
              }
            }
            isDefault: default
            image
            lang
            parentId
            redirectUrl
            tagline
            title
            type
            updatedAt
          }
        }
        endsAt
        id
        startsAt
        teamId
        updatedAt
        points
      }
    }
  }
`

export const news = gql`
  query NewsGet($input: NewsGetInput!) {
    newsGet(input: $input) {
      id
      startsAt
      endsAt
      updatedAt
      teamId
      points
      document {
        items {
          body {
            ... on BodyEmbedSlice {
              sliceType
              embedUrl
              embedText
              embedCaption
              embedCaptionTitle
            }
            ... on BodyImageSlice {
              sliceType
              imgUrl
              imgCaption
              imgCaptionTitle
            }
            ... on BodyQuoteSlice {
              sliceType
              quoteText
              author
              authorTitle
            }
            ... on BodyTextSlice {
              sliceType
              text
            }
          }
          isDefault: default
          image
          lang
          redirectUrl
          tagline
          title
          type
          updatedAt
        }
        parentId
      }
    }
  }
`

export const listActions = gql`
  query ActionsList($input: ListActionsInput!) {
    actionsList(input: $input) {
      items {
        id
        createdAt
        updatedAt
        isRepeatable
        image
        featured
        points
        teamId
        handle
        timesPerDay
        document {
          items {
            isDefault: default
            lang
            parentId
            title
          }
        }
        category {
          id
          name
          foreColor
          backColor
        }
        savingMetrics {
          metrics {
            action
            activity
            co2Saving
            energySaving
            waterSaving
          }
          location {
            __typename
            ... on Region {
              id
              name
            }
            ... on SubRegion {
              id
              name
            }
            ... on DefaultLocation {
              id
              name
            }
            ... on Country {
              id
              name
            }
          }
        }
      }
      nextToken
    }
  }
`

export const action = gql`
  query ActionGet($input: GetActionInput!) {
    actionGet(input: $input) {
      id
      updatedAt
      isRepeatable
      handle
      timesPerDay
      image
      featured
      points
      teamId
      actionGroups {
        actionGroup {
          id
          image
          canBecomeHabit
          actions {
            action {
              id
            }
          }
          document {
            items {
              isDefault: default
              lang
              title
            }
          }
        }
      }
      savingMetrics {
        metrics {
          action
          activity
          co2Saving
          energySaving
          waterSaving
        }
        location {
          __typename
          ... on Region {
            id
            name
          }
          ... on SubRegion {
            id
            name
          }
          ... on DefaultLocation {
            id
            name
          }
          ... on Country {
            id
            name
          }
        }
      }
      sdgs {
        primary
        sdg {
          id
          name
          image
        }
      }
      sdgTargets {
        primary
        sdgTarget {
          id
          translations {
            description
            lang
          }
        }
      }
      document {
        parentId
        items {
          isDefault: default
          lang
          title
          type
          parentId
          updatedAt
          assumptions
          body {
            ... on BodyEmbedSlice {
              sliceType
              embedUrl
              embedText
              embedCaption
              embedCaptionTitle
            }
            ... on BodyImageSlice {
              sliceType
              imgUrl
              imgCaption
              imgCaptionTitle
            }
            ... on BodyQuoteSlice {
              sliceType
              quoteText
              author
              authorTitle
            }
            ... on BodyTextSlice {
              sliceType
              text
            }
          }
        }
      }
      category {
        id
        name
        translations {
          lang
          name
        }
        foreColor
        code
        backTagColor
        backColor
      }
    }
  }
`

export const searchActions = gql`
  query ActionsSearch($input: SearchActionsInput!) {
    actionsSearch(input: $input) {
      items {
        id
        createdAt
        updatedAt
        isRepeatable
        image
        featured
        points
        teamId
        document {
          items {
            isDefault: default
            lang
            parentId
            title
          }
        }
        category {
          id
          name
          foreColor
          backColor
        }
        savingMetrics {
          metrics {
            co2Saving
            energySaving
            waterSaving
          }
        }
      }
      nextToken
    }
  }
`

export const listRegions = gql`
  query RegionsList($input: RegionsList) {
    regionsList(input: $input) {
      items {
        id
        name
      }
    }
  }
`

export const listSubRegions = gql`
  query SubregionsList($input: SubregionsList) {
    subregionsList(input: $input) {
      items {
        id
        name
      }
    }
  }
`

export const listCountries = gql`
  query CountriesList($input: CountriesListInput) {
    countriesList(input: $input) {
      items {
        id
        name
      }
    }
  }
`

export const listActionGroups = gql`
  query ActionGroupsList($input: ListActionGroupsInput!) {
    actionGroupsList(input: $input) {
      items {
        id
        canBecomeHabit
        updatedAt
        image
        featured
        points
        actions {
          action {
            id
          }
        }
        document {
          items {
            isDefault: default
            title
            lang
          }
        }
        badge {
          id
          image
        }
        category {
          id
          name
          foreColor
          backColor
        }
      }
      nextToken
    }
  }
`

export const searchActionGroups = gql`
  query ActionGroupsSearch($input: SearchActionGroupsInput!) {
    actionGroupsSearch(input: $input) {
      items {
        id
        canBecomeHabit
        updatedAt
        image
        featured
        points
        actions {
          action {
            id
          }
        }
        document {
          items {
            isDefault: default
            title
            lang
          }
        }
        badge {
          id
          image
        }
        category {
          id
          name
          foreColor
          backColor
        }
      }
      nextToken
    }
  }
`

export const actionGroup = gql`
  query ActionGroupGet($input: GetActionGroupInput!) {
    actionGroupGet(input: $input) {
      id
      image
      maxIterations
      updatedAt
      handle
      canBecomeHabit
      featured
      footprintPrediction
      points
      actions {
        action {
          id
          image
          isRepeatable
          updatedAt
          document {
            items {
              isDefault: default
              lang
              title
            }
          }
          savingMetrics {
            metrics {
              action
              activity
              co2Saving
              energySaving
              waterSaving
            }
          }
          category {
            id
            name
            foreColor
            backColor
          }
        }
      }
      document {
        parentId
        items {
          updatedAt
          type
          title
          lang
          isDefault: default
          benchmark
          body {
            sliceType
            ... on BodyEmbedSlice {
              sliceType
              embedUrl
              embedText
              embedCaption
              embedCaptionTitle
            }
            ... on BodyImageSlice {
              sliceType
              imgUrl
              imgCaption
              imgCaptionTitle
            }
            ... on BodyQuoteSlice {
              sliceType
              quoteText
              author
              authorTitle
            }
            ... on BodyTextSlice {
              sliceType
              text
            }
          }
        }
      }
      sdgs {
        primary
        sdg {
          id
          name
          image
        }
      }
      sdgTargets {
        primary
        sdgTarget {
          id
          translations {
            description
            lang
          }
        }
      }
      badge {
        id
        image
        badgeType
        updatedAt
        document {
          parentId
          items {
            title
            lang
            isDefault: default
          }
        }
      }
      category {
        id
        name
        foreColor
        backColor
        translations {
          lang
          name
        }
      }
      footprint {
        pk
        questionId
        sk
      }
    }
  }
`

export const listBadges = gql`
  query BadgesList($input: BadgesListInput) {
    badgesList(input: $input) {
      nextToken
      items {
        id
        image
        badgeType
        updatedAt
        document {
          items {
            title
            lang
            isDefault: default
          }
        }
        category {
          id
          name
          foreColor
          backColor
        }
      }
    }
  }
`

export const searchBadges = gql`
  query BadgesSearch($input: BadgesSearchInput) {
    badgesSearch(input: $input) {
      nextToken
      items {
        id
        image
        badgeType
        updatedAt
        document {
          items {
            title
            lang
            isDefault: default
          }
        }
        category {
          id
          name
          foreColor
          backColor
        }
      }
    }
  }
`

export const badge = gql`
  query BadgeGet($input: BadgeIdInput) {
    badgeGet(input: $input) {
      badgeType
      id
      image
      updatedAt
      document {
        parentId
        items {
          type
          title
          lang
          isDefault: default
          body {
            sliceType
            ... on BodyEmbedSlice {
              sliceType
              embedUrl
              embedText
              embedCaption
              embedCaptionTitle
            }
            ... on BodyImageSlice {
              sliceType
              imgUrl
              imgCaption
              imgCaptionTitle
            }
            ... on BodyQuoteSlice {
              sliceType
              quoteText
              author
              authorTitle
            }
            ... on BodyTextSlice {
              sliceType
              text
            }
          }
          parentId
        }
      }
      category {
        id
        name
        foreColor
        backColor
      }
    }
  }
`

export const listCategories = gql`
  query CategoriesList($input: ListCategoriesInput!) {
    categoriesList(input: $input) {
      items {
        id
        name
        code
        backColor
        backTagColor
        foreColor
        updatedAt
        mainCategory {
          id
          name
          backColor
          backTagColor
          foreColor
        }
        translations {
          lang
          name
        }
      }
    }
  }
`

export const listMainCategories = gql`
  query MainCategoriesList($input: ListMainCategoriesInput!) {
    mainCategoriesList(input: $input) {
      items {
        id
        name
        code
        backColor
        backTagColor
        foreColor
        updatedAt
        translations {
          lang
          name
        }
      }
    }
  }
`

export const listChallenges = gql`
  query ChallengesList($input: ChallengesListInput) {
    challengesList(input: $input) {
      nextToken
      items {
        id
        uid
        startsAt
        endsAt
        currentAmount
        targetAmount
        type
        name
        updatedAt
        userCount
        versionType
        document {
          items {
            lang
            image
            isDefault: default
          }
          parentId
        }
        team {
          id
          uid
          name
          oldId
        }
      }
    }
  }
`

export const listMetrics = gql`
  query ListMetrics($lang: Lang) {
    listMetrics(lang: $lang) {
      id
      name
      color
      icon
      lang
      type
      unit
    }
  }
`

export const challenge = gql`
  query ChallengeGet($input: ChallengeGetInput) {
    challengeGet(input: $input) {
      id
      oldId
      uid
      startsAt
      endsAt
      currentAmount
      targetAmount
      personalTargetAmount
      personalTargetPoints
      name
      type
      updatedAt
      leaderboardGroupType
      leaderboardMinMembers
      userCount
      privacyPolicy
      versionType
      document {
        parentId
        items {
          isDefault: default
          image
          lang
          parentId
          sponsor
          subtitle
          title
          type
          body {
            sliceType
            ... on BodyEmbedSlice {
              sliceType
              embedUrl
              embedText
              embedCaption
              embedCaptionTitle
            }
            ... on BodyImageSlice {
              sliceType
              imgUrl
              imgCaption
              imgCaptionTitle
            }
            ... on BodyQuoteSlice {
              sliceType
              quoteText
              author
              authorTitle
            }
            ... on BodyTextSlice {
              sliceType
              text
            }
          }
          prizes {
            ... on BodyImageSlice {
              sliceType
              imgUrl
              imgCaption
              imgCaptionTitle
            }
          }
        }
      }
      metric {
        id
        lang
        name
        type
        unit
        color
        icon
      }
      sorting
      team {
        id
        oldId
        uid
        name
        accessType
        stage
        featuredSorting
        homeFeaturedSorting
        isHomeFeatured
        stakeholderType
        visibilityOptions {
          leaderboard
          members
          metric
        }
        groups {
          groupId
          name
        }
      }
    }
  }
`

export const searchChallenges = gql`
  query ChallengesSearch($input: ChallengesSearchInput!) {
    challengesSearch(input: $input) {
      nextToken
      items {
        id
        uid
        startsAt
        endsAt
        currentAmount
        targetAmount
        type
        name
        userCount
        updatedAt
        versionType
        document {
          items {
            title
            lang
            image
            isDefault: default
          }
          parentId
        }
        team {
          id
          uid
          name
          oldId
        }
      }
    }
  }
`

export const listFootprintQuestions = gql`
  query FootprintQuestionsList {
    footprintQuestionsList {
      items {
        description
        pk
        type
        sk
      }
    }
  }
`

export const challengeUserLeaderboardList = gql`
  query ChallengeUserLeaderboardList(
    $input: ChallengeUserLeaderboardListInput
  ) {
    challengeUserLeaderboardList(input: $input) {
      items {
        rank
        challengeId
        ... on ChallengeUserLeaderboard {
          user {
            first_name
            last_name
            nickname
            email
            uid
            sub
          }
          metricSum
        }
      }
      nextToken
    }
  }
`

export const challengeGroupLeaderboardList = gql`
  query ChallengeGroupLeaderboardList($input: ChallengeGroupLeaderboardList) {
    challengeGroupLeaderboardList(input: $input) {
      nextToken
      items {
        challengeId
        rank
        ... on ChallengeGroupsLeaderboardByAverage {
          groupId
          rank
          metricAverage
        }
        ... on ChallengeGroupsLeaderboardBySum {
          groupId
          rank
          metricSum
        }
      }
    }
  }
`

export const challengeIntraGroupLeaderboardList = gql`
  query ChallengeIntraGroupLeaderboardList(
    $input: ChallengeIntraGroupLeaderboardList
  ) {
    challengeIntraGroupLeaderboardList(input: $input) {
      items {
        challengeId
        rank
        ... on ChallengeLeaderboardInsideGroup {
          groupId
          metricSum
          user {
            sub
            uid
            nickname
            first_name
            last_name
          }
        }
      }
      nextToken
    }
  }
`

export const categoryGet = gql`
  query CategoryGet($input: GetCategoryInput!) {
    categoryGet(input: $input) {
      id
      name
      code
      backColor
      backTagColor
      foreColor
      mainCategory {
        id
        name
      }
      translations {
        name
        lang
      }
      updatedAt
    }
  }
`

export const mainCategoryGet = gql`
  query MainCategoryGet($input: GetMainCategoryInput!) {
    mainCategoryGet(input: $input) {
      id
      name
      code
      backColor
      backTagColor
      foreColor
      translations {
        name
        description
        cta
        lang
      }
      updatedAt
    }
  }
`

export const sdgGet = gql`
  query SdgGet($input: GetSdgInput!) {
    sdgGet(input: $input) {
      id
      name
      image
      code
      translations {
        name
        image
        lang
      }
      updatedAt
    }
  }
`

export const sdgTargetGet = gql`
  query SdgTargetGet($input: GetSdgTargetInput!) {
    sdgTargetGet(input: $input) {
      id
      code
      translations {
        description
        lang
      }
      updatedAt
    }
  }
`

export const topicGet = gql`
  query TopicGet($input: GetTopicInput!) {
    topicGet(input: $input) {
      id
      name
      code
      mainTopic {
        id
        name
      }
      translations {
        name
        lang
      }
      updatedAt
    }
  }
`

export const categoriesSearch = gql`
  query CategoriesSearch($input: SearchCategoriesInput!) {
    categoriesSearch(input: $input) {
      items {
        id
        name
        code
        backColor
        backTagColor
        foreColor
        updatedAt
        mainCategory {
          id
          name
          backColor
          foreColor
        }
        translations {
          lang
          name
        }
      }
    }
  }
`

export const mainCategoriesSearch = gql`
  query MainCategoriesSearch($input: SearchMainCategoriesInput!) {
    mainCategoriesSearch(input: $input) {
      items {
        id
        name
        code
        backColor
        backTagColor
        foreColor
        updatedAt
        translations {
          lang
          name
        }
      }
    }
  }
`

export const sdgsSearch = gql`
  query SdgsSearch($input: SearchSdgsInput!) {
    sdgsSearch(input: $input) {
      items {
        id
        name
        image
        code
        updatedAt
        translations {
          image
          lang
          name
        }
      }
    }
  }
`

export const sdgTargetsSearch = gql`
  query SdgTargetsSearch($input: SearchSdgTargetsInput!) {
    sdgTargetsSearch(input: $input) {
      items {
        id
        code
        updatedAt
        translations {
          lang
          description
        }
      }
    }
  }
`

export const topicsSearch = gql`
  query TopicsSearch($input: SearchTopicsInput!) {
    topicsSearch(input: $input) {
      items {
        id
        name
        code
        mainTopic {
          id
          name
        }
        translations {
          name
          lang
        }
        updatedAt
      }
    }
  }
`

export const translationsExportUrlGet = gql`
  query TranslationsExportUrlGet($input: GetTranslationsExportUrlInput) {
    translationsExportUrlGet(input: $input) {
      s3Url
    }
  }
`

export const translationsImportUrlGet = gql`
  query TranslationsImportUrlGet($input: GetTranslationsImportUrlInput) {
    translationsImportUrlGet(input: $input) {
      s3Url
      jobId
    }
  }
`

export const translationsJobGet = gql`
  query TranslationsJobGet($input: GetTranslationsJobInput) {
    translationsJobGet(input: $input) {
      status
      error
    }
  }
`

export const teamsList = gql`
  query TeamsList($input: TeamListInput) {
    teamsList(input: $input) {
      items {
        accessType
        featuredSorting
        homeFeaturedSorting
        isHomeFeatured
        id
        isPrimary
        suggestFootprint
        document {
          items {
            isDefault: default
            lang
            logo
            title
          }
        }
        name
        stage
        stakeholderType
        organizationType
        uid
        updatedAt
        userCount
        visibilityOptions {
          leaderboard
          members
          metric
        }
        groups {
          groupId
          name
          userCount
        }
      }
      nextToken
    }
  }
`

export const teamsSearch = gql`
  query TeamsSearch($input: TeamSearchInput) {
    teamsSearch(input: $input) {
      items {
        accessType
        featuredSorting
        homeFeaturedSorting
        isHomeFeatured
        id
        isPrimary
        suggestFootprint
        document {
          items {
            isDefault: default
            lang
            logo
          }
        }
        name
        stage
        stakeholderType
        organizationType
        uid
        updatedAt
        userCount
        visibilityOptions {
          leaderboard
          members
          metric
        }
        groups {
          groupId
          name
          userCount
        }
      }
      nextToken
    }
  }
`

export const teamGet = gql`
  query TeamGet($input: TeamGetInput) {
    teamGet(input: $input) {
      accessType
      canLeave
      featuredSorting
      homeFeaturedSorting
      isHomeFeatured
      isPrimary
      suggestFootprint
      groups {
        groupId
        name
        userCount
      }
      accessCodes {
        code
        codeType
        teamId
        groupId
        policy
        iterationCount
        maxIteration
      }
      id
      name
      metrics {
        amount
        metric {
          color
          icon
          id
          lang
          name
          type
          unit
        }
      }
      oldId
      stage
      stakeholderType
      organizationType
      uid
      updatedAt
      userCount
      visibilityOptions {
        leaderboard
        members
        metric
      }
      document {
        parentId
        items {
          parentId
          lang
          type
          isDefault: default
          body {
            ... on BodyEmbedSlice {
              sliceType
              embedUrl
              embedText
              embedCaption
              embedCaptionTitle
            }
            ... on BodyImageSlice {
              sliceType
              imgUrl
              imgCaption
              imgCaptionTitle
            }
            ... on BodyQuoteSlice {
              sliceType
              quoteText
              author
              authorTitle
            }
            ... on BodyTextSlice {
              sliceType
              text
            }
          }
          home {
            image
            title
            subtitle
          }
          updatedAt
          title
          logo
          cover
        }
      }
      availableAtLocations {
        ... on DefaultLocation {
          id
          name
        }
        ... on Country {
          id
          name
        }
        ... on Region {
          id
          name
        }
        ... on SubRegion {
          id
          name
        }
      }
      restrictedAtLocations {
        ... on DefaultLocation {
          id
          name
        }
        ... on Country {
          id
          name
        }
        ... on Region {
          id
          name
        }
        ... on SubRegion {
          id
          name
        }
      }
    }
  }
`

export const teamUsersSearch = gql`
  query TeamUsersSearch($input: TeamUsersSearchInput) {
    teamUsersSearch(input: $input) {
      items {
        user {
          sub
          email
          first_name
          last_name
          uid
        }
        isAdmin
        group {
          groupId
          name
        }
      }
      nextToken
    }
  }
`

export const activationCodeGet = gql`
  query ActivationCodeGet($input: ActivationCodeGetInput!) {
    activationCodeGet(input: $input) {
      ... on TeamActivationCode {
        code
        codeType
        policy
        teamId
        groupId
        challengeId
        iterationCount
        maxIteration
        domainsWhiteList
      }
      ... on ChallengeActivationCode {
        code
        codeType
        policy
        challengeIdChallengeCode: challengeId
        iterationCount
        maxIteration
        domainsWhiteList
      }
      ... on WrapperActivationCode {
        code
        codeType
        policy
        iterationCount
        maxIteration
        domainsWhiteList
        wrappedCodes
      }
    }
  }
`

export const mediaCategoriesGet = gql`
  query {
    __type(name: "MediaCategory") {
      name
      enumValues {
        name
      }
    }
  }
`

export const teamContractsList = gql`
  query TeamContractsList($input: TeamContractsListInput!) {
    teamContractsList(input: $input) {
      items {
        id
        startDate
        endDate
        sellingPrice
        targetMembers
        activatedAt
      }
    }
  }
`

export const productsStagedList = gql`
  query ProductsStagedList($input: ListStagedProductsInput) {
    productsStagedList(input: $input) {
      items {
        availability
        boughtCount
        defaultCurrency
        document {
          items {
            isDefault: default
            lang
            parentId
            title
            updatedAt
          }
        }
        handle
        hasSibling
        id
        image
        inventoryCount
        limitPerUser
        prices {
          amount
          currency
        }
        projectId
        sponsoredBy
        stage
        status
        visibility
        team {
          id
          document {
            items {
              title
              isDefault: default
            }
          }
        }
        type
        updatedAt
      }
      nextToken
    }
  }
`

export const productsSearch = gql`
  query ProductsSearch($input: SearchProductsInput!) {
    productsSearch(input: $input) {
      items {
        availability
        boughtCount
        defaultCurrency
        document {
          items {
            isDefault: default
            lang
            parentId
            title
            updatedAt
          }
        }
        handle
        hasSibling
        id
        image
        inventoryCount
        limitPerUser
        prices {
          amount
          currency
        }
        projectId
        sponsoredBy
        stage
        status
        visibility
        team {
          id
          document {
            items {
              title
              isDefault: default
            }
          }
        }
        type
        updatedAt
      }
      nextToken
    }
  }
`

export const productStagedGet = gql`
  query ProductStagedGet($input: GetStagedProductInput!) {
    productStagedGet(input: $input) {
      availability
      boughtCount
      defaultCurrency
      hasSibling
      document {
        items {
          isDefault: default
          lang
          parentId
          title
          updatedAt
          body {
            ... on BodyEmbedSlice {
              sliceType
              embedUrl
              embedText
              embedCaption
              embedCaptionTitle
            }
            ... on BodyImageSlice {
              sliceType
              imgUrl
              imgCaption
              imgCaptionTitle
            }
            ... on BodyQuoteSlice {
              sliceType
              quoteText
              author
              authorTitle
            }
            ... on BodyTextSlice {
              sliceType
              text
            }
          }
        }
      }
      handle
      id
      image
      inventoryCount
      limitPerUser
      prices {
        amount
        currency
      }
      projectId
      sponsoredBy
      stage
      status
      sorting
      team {
        id
        document {
          items {
            title
            isDefault: default
          }
        }
      }
      type
      gamificationType
      visibility
      availableAtLocations {
        ... on DefaultLocation {
          id
          name
          __typename
        }
        ... on Country {
          id
          name
          __typename
        }
        ... on Region {
          id
          name
          __typename
        }
        ... on SubRegion {
          id
          name
          __typename
        }
      }
      updatedAt
    }
  }
`

export const nftCatalogsListGql = gql`
  query NftCatalogsList($input: ListNftCatalogsInput!) {
    nftCatalogsList(input: $input) {
      items {
        id
        image
        name
        status
        mintAt
        updatedAt
      }
      nextToken
    }
  }
`

export const nftCatalogGet = gql`
  query NftCatalogGet($input: GetNftCatalogInput!) {
    nftCatalogGet(input: $input) {
      id
      image
      name
      description
      projectId
      sourceId
      sourceKind
      kind
      status
      mintAt
      symbol
      attributes {
        trait_type
        value
      }
      contracts
    }
  }
`

export const activationCodeList = gql`
  query ActivationCodeList($input: ActivationCodeListInput!) {
    activationCodeList(input: $input) {
      items {
        code
        codeType
        policy
        iterationCount
        maxIteration
        updatedAt
        ... on ChallengeActivationCode {
          challengeId
        }
        ... on NftActivationCode {
          nftCatalogId
        }
        ... on TeamActivationCode {
          teamId
          groupId
        }
      }
      nextToken
    }
  }
`

export const notificationTemplatesStagedList = gql`
  query NotificationTemplatesStagedList(
    $input: ListStagedNotificationTemplatesInput!
  ) {
    notificationTemplatesStagedList(input: $input) {
      items {
        id
        updatedAt
        handle
        stage
        status
        format
        type
        validationStatus
        invalidLangs {
          lang
          validation {
            field
            errors
          }
        }
        defaultDataJson
        hasSibling
        requiredEnricherIds
        document {
          items {
            isDefault: default
            lang
            parentId
            title
            type
          }
        }
      }
      nextToken
    }
  }
`

export const notificationTemplatesSearch = gql`
  query NotificationTemplatesSearch($input: SearchNotificationTemplatesInput!) {
    notificationTemplatesSearch(input: $input) {
      items {
        id
        updatedAt
        handle
        stage
        status
        format
        type
        validationStatus
        invalidLangs {
          lang
          validation {
            field
            errors
          }
        }
        defaultDataJson
        hasSibling
        requiredEnricherIds
        document {
          items {
            isDefault: default
            lang
            parentId
            title
            type
          }
        }
      }
      nextToken
    }
  }
`

export const enrichersList = gql`
  query EnrichersList($input: ListEnrichersInput!) {
    enrichersList(input: $input) {
      items {
        id
        name
        exportSchema
      }
    }
  }
`

export const notificationTemplateStagedGet = gql`
  query NotificationTemplateStagedGet(
    $input: GetStagedNotificationTemplateInput!
  ) {
    notificationTemplateStagedGet(input: $input) {
      id
      updatedAt
      handle
      stage
      status
      format
      type
      validationStatus
      invalidLangs {
        lang
        validation {
          field
          errors
        }
      }
      defaultDataJson
      hasSibling
      requiredEnricherIds
      document {
        items {
          isDefault: default
          lang
          parentId
          title
          subtitle
          type
          contentBody
          richContentBody
        }
      }
    }
  }
`

export const missionCatalogsStagedList = gql`
  query MissionCatalogsStagedList($input: ListStagedMissionCatalogsInput) {
    missionCatalogsStagedList(input: $input) {
      items {
        id
        handle
        hasSibling
        image
        projectId
        stage
        status
        type
        specialType
        difficultyLevel
        timeframe
        target
        suspended
        team {
          id
          document {
            items {
              title
              isDefault: default
            }
          }
        }
        document {
          items {
            title
            lang
            isDefault: default
          }
          parentId
        }
        updatedAt
      }
      nextToken
    }
  }
`

export const missionCatalogsSearch = gql`
  query MissionCatalogsSearch($input: SearchMissionCatalogsInput!) {
    missionCatalogsSearch(input: $input) {
      items {
        id
        handle
        hasSibling
        image
        projectId
        stage
        status
        type
        specialType
        difficultyLevel
        timeframe
        target
        suspended
        team {
          id
          document {
            items {
              title
              isDefault: default
            }
          }
        }
        document {
          items {
            title
            lang
            isDefault: default
          }
          parentId
        }
        updatedAt
      }
      nextToken
    }
  }
`

export const missionCatalogStagedGet = gql`
  query MissionCatalogStagedGet($input: GetStagedMissionCatalogInput!) {
    missionCatalogStagedGet(input: $input) {
      id
      handle
      image
      projectId
      stage
      status
      timeframe
      type
      specialType
      updatedAt
      difficultyLevel
      target
      hasSibling
      suspended
      team {
        id
        document {
          items {
            title
            isDefault: default
          }
        }
      }
      document {
        items {
          isDefault: default
          lang
          parentId
          title
          type
        }
        parentId
      }
    }
  }
`

export const listCurrencies = gql`
  query CurrenciesList($input: ListCurrenciesInput!) {
    currenciesList(input: $input) {
      items {
        id
        name
        projectId
      }
      nextToken
    }
  }
`

export const listLeagues = gql`
  query LeaguesList($input: LeaguesListInput!) {
    leaguesList(input: $input) {
      items {
        id
        name
        type
        updatedAt
        document {
          items {
            isDefault: default
            title
            lang
            logo
          }
        }
      }
      nextToken
    }
  }
`

export const leagueGet = gql`
  query LeagueGet($input: LeagueGetInput!) {
    leagueGet(input: $input) {
      id
      name
      type
      updatedAt
      document {
        parentId
        items {
          isDefault: default
          title
          lang
          logo
        }
      }
    }
  }
`

export const surveysList = gql`
  query SurveysList($input: ListSurveysInput) {
    surveysList(input: $input) {
      items {
        id
        provider
        stage
        status
        handle
        teamId
        team {
          id
          document {
            items {
              title
              isDefault: default
            }
          }
        }
        projectId
        suspended
        storeResponses
        useSecret
        hasSibling
        startsAt
        endsAt
        document {
          parentId
          items {
            title
            lang
            default
            formId
            formUrl
            webhookUrl
            webhookSecret
            type
            updatedAt
          }
        }
        createdAt
        updatedAt
        createdAtT
        updatedAtT
        lastUpdatedAt
      }
      nextToken
    }
  }
`

export const surveysStagedList = gql`
  query SurveysStagedList($input: ListStagedSurveysInput) {
    surveysStagedList(input: $input) {
      items {
        id
        provider
        stage
        status
        handle
        teamId
        team {
          id
          document {
            items {
              title
              isDefault: default
            }
          }
        }
        projectId
        suspended
        storeResponses
        useSecret
        hasSibling
        startsAt
        endsAt
        document {
          parentId
          items {
            title
            lang
            isDefault: default
            formId
            formUrl
            webhookUrl
            webhookSecret
            type
            updatedAt
          }
        }
        createdAt
        updatedAt
        createdAtT
        updatedAtT
        lastUpdatedAt
      }
      nextToken
    }
  }
`

export const surveyStagedGet = gql`
  query SurveyStagedGet($input: GetStagedSurveyInput!) {
    surveyStagedGet(input: $input) {
      id
      provider
      stage
      status
      handle
      teamId
      team {
        id
        document {
          items {
            title
            isDefault: default
          }
        }
      }
      projectId
      suspended
      storeResponses
      useSecret
      hasSibling
      startsAt
      endsAt
      document {
        parentId
        items {
          title
          lang
          isDefault: default
          formId
          formUrl
          webhookUrl
          webhookSecret
          type
          updatedAt
          body {
            ... on BodyEmbedSlice {
              sliceType
              embedUrl
              embedText
              embedCaption
              embedCaptionTitle
            }
            ... on BodyImageSlice {
              sliceType
              imgUrl
              imgCaption
              imgCaptionTitle
            }
            ... on BodyQuoteSlice {
              sliceType
              quoteText
              author
              authorTitle
            }
            ... on BodyTextSlice {
              sliceType
              text
            }
          }
        }
      }
      createdAt
      updatedAt
      createdAtT
      updatedAtT
      lastUpdatedAt
    }
  }
`

export const surveysSearch = gql`
    query SurveysSearch($input: SearchSurveysInput!) {
        surveysSearch(input: $input) {
            items {
                id
                provider
                stage
                status
                handle
                teamId
                team {
                    id
                    document {
                        items {
                            title
                            isDefault: default
                        }
                    }
                }
                projectId
                suspended
                storeResponses
                useSecret
                hasSibling
                startsAt
                endsAt
                document {
                    parentId
                    items {
                        title
                        lang
                        default
                        formId
                        formUrl
                        webhookUrl
                        webhookSecret
                        type
                        updatedAt
                    }
                }
                createdAt
                updatedAt
                createdAtT
                updatedAtT
                lastUpdatedAt
            }
            nextToken
        }
    }
`

export const teamSurveysList = gql`
  query TeamSurveysList($input: TeamSurveysListInput!) {
    teamSurveysList(input: $input) {
      items {
        id
        provider
        stage
        status
        handle
        teamId
        team {
          id
          document {
            items {
              title
              isDefault: default
            }
          }
        }
        projectId
        suspended
        storeResponses
        useSecret
        hasSibling
        startsAt
        endsAt
        document {
          parentId
          items {
            title
            lang
            default
            formId
            formUrl
            webhookUrl
            webhookSecret
            type
            updatedAt
          }
        }
        createdAt
        updatedAt
        createdAtT
        updatedAtT
        lastUpdatedAt
      }
      nextToken
    }
  }
`
