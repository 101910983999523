import {
  AppBar,
  Autocomplete,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material"
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { MainContext } from "../../controllers/main"
import DataCard from "../global/dataCard"
import { TeamsContext } from "../../controllers/teams"
import {
  capitalizeFirstCharacter,
  enumAsArray,
  generateRandomString,
} from "easy-ts-utils"
import {
  ActivationCodePolicy,
  ActivationCodeType,
  TeamAccessType,
  TeamOrganizationType,
  TeamStage,
  TeamStakeholderType,
  TeamVisibilityOptionType,
} from "../../services/config/enum"
import {
  AddCircleRounded,
  ArrowForwardIosRounded,
  CancelRounded,
  ChevronLeftRounded,
  CloseRounded,
  ContentCopyRounded,
  DeleteForeverRounded,
  DeleteRounded,
  DragHandleRounded,
  InfoRounded,
} from "@mui/icons-material"
import { useNavigate, useSearchParams } from "react-router-dom"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import noImagePlaceholder from "../../assets/images/no-image-placeholder.jpeg"
import { JourneysContext } from "../../controllers/journeys"
import Journey from "../../models/journey"
import {
  DialogTransition,
  copyTextToClipboard,
  deepCopy,
  parseStringDate,
  splitCamelCase,
} from "../../services/utilities/utility"
import JourneysList from "../../views/journey/journeysList"
import CantPerformActionDialog from "../global/cantPerformActionDialog"
import { ActivationCodesContext } from "../../controllers/activationCodes"
import { LoadingButton } from "@mui/lab"
import GroupCard from "./groupCard"
import "../../styles/teamGeneralEdit.scss"
import News from "../../models/news"
import { NewsContext } from "../../controllers/news"
import CreateNewsDialog from "../news/createNewsDialog"
import { ChallengesContext } from "../../controllers/challenges"
import CreateChallengeDialog from "../challenge/createChallengeDialog"
import Challenge from "../../models/challenge"
import Team from "../../models/team"
import CreateContractDialog from "./createContractDialog"
import TeamContractCard from "./teamContractCard"
import { LocationAutocompleteOption } from "../../services/config/interfaces"
import { CountriesContext } from "../../controllers/countries"

const TeamGeneralEdit = ({
  setFeedbackOpen,
  setGlobalPreviewOpen,
}: {
  setFeedbackOpen: Dispatch<SetStateAction<boolean>>
  setGlobalPreviewOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const [searchParams] = useSearchParams()
  const { showPadding, setAnimation, user } = useContext(MainContext)
  const {
    createActivationCode,
    loading: createActivationCodeLoading,
    deleteActivationCode,
  } = useContext(ActivationCodesContext)
  const {
    currentTeam,
    setCurrentTeam,
    editMode,
    nameError,
    setNameError,
    expirationDateError,
    setExpirationDateError,
    currentTeamJourneysList,
    setCurrentTeamJourneysList,
    currentTeamNewsList,
    setCurrentTeamNewsList,
    currentTeamChallengesList,
    setCurrentTeamChallengesList,
    currentTeamContractsList,
  } = useContext(TeamsContext)
  const {
    setSearchValue,
    setSearchLanguage,
    setSearchTopic,
    setSearchSdg,
    setSearchSdgTarget,
    setSearchType,
    setJourneysList,
  } = useContext(JourneysContext)
  const { deleteNews, setLoading: setNewsLoading } = useContext(NewsContext)
  const { deleteChallenge, setLoading: setChallengesLoading } =
    useContext(ChallengesContext)
  const {
    regionsList,
    subRegionsList,
    countriesList,
    loading: countriesControllerLoading,
  } = useContext(CountriesContext)
  const navigate = useNavigate()

  // team journeys
  const [selectedJourneys, setSelectedJourneys] = useState<Journey[]>([])
  const [journeysToFilter, setJourneysToFilter] = useState<Journey[]>([])
  const [addJourneysDialogOpen, setAddJourneysDialogOpen] =
    useState<boolean>(false)

  // can't add journeys dialog
  const [cantAddJourneyDialogOpen, setCantAddJourneyDialogOpen] =
    useState<boolean>(false)

  // reset journeys list filters
  const resetJourneysFilters = () => {
    setSearchValue("")
    setSearchLanguage(null)
    setSearchTopic(null)
    setSearchSdg(null)
    setSearchSdgTarget(null)
    setSearchType(["Free", "Business"])
  }

  // drag and drop
  const onDragEnd = (result: any) => {
    const { destination, source, draggableId } = result

    if (destination.index === source.index) {
      return
    }

    let sourceItem = currentTeamJourneysList[source.index]

    let indexToDelete = currentTeamJourneysList.findIndex(
      (item) => item.journey.id === draggableId
    )
    currentTeamJourneysList.splice(indexToDelete, 1)
    currentTeamJourneysList.splice(destination.index, 0, sourceItem)

    setCurrentTeamJourneysList([...currentTeamJourneysList])
  }

  // premium journeys max number and dialog
  const journeysMaxNumber = 80
  const [maxNumberDialogOpen, setMaxNumberDialogOpen] = useState<boolean>(false)

  // activation code dialog
  const [activationCodeDialogOpen, setActivationCodeDialogOpen] =
    useState<boolean>(false)

  const handleActivationCodeDialogChange = () => {
    setActivationCodeDialogOpen((current) => !current)
  }

  // add contract dialog
  const [addContractDialogOpen, setAddContractDialogOpen] =
    useState<boolean>(false)

  const handleAddContractDialogChange = () => {
    setAddContractDialogOpen((current) => !current)
  }

  // delete news
  const [newsToDelete, setNewsToDelete] = useState<News | null>(null)
  const [deletingNews, setDeletingNews] = useState<boolean>(false)
  const [deleteNewsDialogOpen, setDeleteNewsDialogOpen] =
    useState<boolean>(false)

  const handleDeleteNewsDialogChange = () => {
    setDeleteNewsDialogOpen((current) => !current)
  }

  // delete challenge
  const [challengeToDelete, setChallengeToDelete] = useState<Challenge | null>(
    null
  )
  const [deletingChallenge, setDeletingChallenge] = useState<boolean>(false)
  const [deleteChallengeDialogOpen, setDeleteChallengeDialogOpen] =
    useState<boolean>(false)

  const handleDeleteChallengeDialogChange = () => {
    setDeleteChallengeDialogOpen((current) => !current)
  }

  // create news dialog
  const [createNewsDialogOpen, setCreateNewsDialogOpen] =
    useState<boolean>(false)

  const handleCreateNewsDialogChange = () => {
    setCreateNewsDialogOpen((current) => !current)
  }

  // create challenge dialog
  const [createChallengeDialogOpen, setCreateChallengeDialogOpen] =
    useState<boolean>(false)

  const handleCreateChallengeDialogChange = () => {
    setCreateChallengeDialogOpen((current) => !current)
  }

  // switch
  const [selectedSwitchPage, setSelectedSwitchPage] = useState<number>(0)

  useEffect(() => {
    setSearchGroupsValue("")
    setSearchJourneysValue("")
  }, [selectedSwitchPage])

  // view groups if user is coming from activation codes list (groupId query param)
  useEffect(() => {
    const groupId = searchParams.get("groupId")

    if (groupId) {
      setSelectedSwitchPage(1)

      // scroll group card into view and make it blink
      setTimeout(() => {
        const groupCard = document.getElementById(groupId)
        groupCard.scrollIntoView({ behavior: "smooth" })
        const intervalId = setInterval(() => {
          if (
            !groupCard.style.backgroundColor ||
            groupCard.style.backgroundColor === "white"
          ) {
            groupCard.style.backgroundColor = "lightgray"
          } else {
            groupCard.style.backgroundColor = "white"
          }
        }, 200)
        setTimeout(() => {
          clearInterval(intervalId)
        }, 1200)
      }, 100)
    }
  }, [])

  // delete activation code
  const [codeToDelete, setCodeToDelete] = useState<string | null>(null)
  const [deletingCode, setDeletingCode] = useState<boolean>(false)
  const [deleteCodeDialogOpen, setDeleteCodeDialogOpen] =
    useState<boolean>(false)

  const handleDeleteCodeDialogChange = () => {
    setDeleteCodeDialogOpen((current) => !current)
  }

  // search groups
  const [searchGroupsValue, setSearchGroupsValue] = useState<string>("")

  const groupsListMemo = useMemo(() => {
    if (searchGroupsValue) {
      return currentTeam.groups.filter((item) =>
        item.name.toLowerCase().includes(searchGroupsValue.toLowerCase())
      )
    }

    return currentTeam.groups
  }, [currentTeam, searchGroupsValue])

  // search journeys
  const [searchJourneysValue, setSearchJourneysValue] = useState<string>("")

  const journeysListMemo = useMemo(() => {
    if (searchJourneysValue) {
      return currentTeamJourneysList.filter((item) =>
        item.journey.title
          .toLowerCase()
          .includes(searchJourneysValue.toLowerCase())
      )
    }

    return currentTeamJourneysList
  }, [currentTeamJourneysList, searchJourneysValue])

  // if show only active contract or all contracts
  const [showAllContracts, setShowAllContracts] = useState<boolean>(false)

  // is primary alert
  const [isPrimaryAlertOpen, setIsPrimaryAlertOpen] = useState<boolean>(false)

  // contract incomplete alert (missing sellingPrice or targetMembers from current contract)
  const [contractIncompleteAlertOpen, setContractIncompleteAlertOpen] =
    useState<boolean>(false)

  // cannot be home featured (missing sellingPrice or targetMembers from current contract or home document)
  const [cannotBeHomeFeaturedAlertOpen, setCannotBeHomeFeaturedAlertOpen] =
    useState<boolean>(false)

  // stage info dialog
  const [stageInfoDialogOpen, setStageInfoDialogOpen] = useState<boolean>(false)

  // home featured info dialog
  const [homeFeaturedInfoDialogOpen, setHomeFeaturedInfoDialogOpen] =
    useState<boolean>(false)

  return (
    <Stack>
      {/* switch between various items */}
      <div
        style={{
          width: "100%",
          height: 56,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
          boxShadow: "0px 0px 4px 1px rgba(0,0,0,0.14)",
          position: "relative",
          zIndex: 10,
        }}
      >
        <div
          style={{
            height: 24,
            width: 480,
            borderRadius: 16,
            border: "1px solid #bdbdbd",
            position: "relative",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              height: 24,
              width: "20%",
              fontSize: 11,
              position: "relative",
              zIndex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              color: selectedSwitchPage === 0 ? "white" : null,
              transition: "200ms",
            }}
            onClick={() => {
              setSelectedSwitchPage(0)
            }}
          >
            <Typography
              variant="overline"
              style={{
                fontWeight: 500,
              }}
            >
              General
            </Typography>
          </div>
          <div
            style={{
              height: 24,
              width: "20%",
              fontSize: 11,
              position: "relative",
              zIndex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              color: selectedSwitchPage === 1 ? "white" : null,
              transition: "200ms",
            }}
            onClick={() => {
              setSelectedSwitchPage(1)
            }}
          >
            <Typography
              variant="overline"
              style={{
                fontWeight: 500,
              }}
            >
              Groups
            </Typography>
          </div>
          <div
            style={{
              height: 24,
              width: "20%",
              fontSize: 11,
              position: "relative",
              zIndex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              color: selectedSwitchPage === 2 ? "white" : null,
              transition: "200ms",
            }}
            onClick={() => {
              setSelectedSwitchPage(2)
            }}
          >
            <Typography
              variant="overline"
              style={{
                fontWeight: 500,
              }}
            >
              Journeys
            </Typography>
          </div>
          <div
            style={{
              height: 24,
              width: "20%",
              fontSize: 11,
              position: "relative",
              zIndex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              color: selectedSwitchPage === 3 ? "white" : null,
              transition: "200ms",
            }}
            onClick={() => {
              setSelectedSwitchPage(3)
            }}
          >
            <Typography
              variant="overline"
              style={{
                fontWeight: 500,
              }}
            >
              News
            </Typography>
          </div>
          <div
            style={{
              height: 24,
              width: "20%",
              fontSize: 11,
              position: "relative",
              zIndex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              color: selectedSwitchPage === 4 ? "white" : null,
              transition: "200ms",
            }}
            onClick={() => {
              setSelectedSwitchPage(4)
            }}
          >
            <Typography
              variant="overline"
              style={{
                fontWeight: 500,
              }}
            >
              Challenges
            </Typography>
          </div>
          <div
            style={{
              height: 22,
              width: "20%",
              borderRadius: 16,
              backgroundColor: "#5262ba",
              position: "absolute",
              left:
                selectedSwitchPage === 0
                  ? 0
                  : selectedSwitchPage === 1
                  ? "20%"
                  : selectedSwitchPage === 2
                  ? "40%"
                  : selectedSwitchPage === 3
                  ? "60%"
                  : "80%",
              top: 0,
              transition: "250ms",
            }}
          />
        </div>
      </div>
      <CardContent
        style={{
          height:
            showPadding === "yes"
              ? "calc(100vh - 256px)"
              : "calc(100vh - 239px)",
          maxHeight:
            showPadding === "yes"
              ? "calc(100vh - 256px)"
              : "calc(100vh - 239px)",
          overflowY: "scroll",
        }}
      >
        {selectedSwitchPage === 0 ? (
          <Stack spacing={2}>
            <Typography variant="h6" className="card-title">
              Data
            </Typography>
            <Stack direction="row" gap={1}>
              <DataCard
                title="Info"
                htmlDescription={`
              <p style="line-height: 1.3"><span style="font-weight: 700; font-size: 1rem">${
                currentTeam.userCount
              }</span> <span style="font-weight: 300; font-size: 0.9rem">members and </span>
              <span style="font-weight: 700; font-size: 1rem">${
                currentTeam.groups.length
              }</span> <span style="font-weight: 300; font-size: 0.9rem">${
                  currentTeam.groups.length === 1 ? "group" : "groups"
                }</span></p>
              <p style="line-height: 1.3"><span style="font-weight: 700; font-size: 1rem">${
                currentTeamJourneysList.length
              }</span> <span style="font-weight: 300; font-size: 0.9rem">${
                  currentTeamJourneysList.length === 1 ? "Journey" : "Journeys"
                }</span></p>
            <p style="line-height: 1.3"><span style="font-weight: 700; font-size: 1rem">${
              currentTeamNewsList.length
            }</span> <span style="font-weight: 300; font-size: 0.9rem">News</span></p>
            <p style="line-height: 1.3"><span style="font-weight: 700; font-size: 1rem">${
              currentTeamChallengesList.length
            }</span> <span style="font-weight: 300; font-size: 0.9rem">${
                  currentTeamChallengesList.length === 1
                    ? "Challenge"
                    : "Challenges"
                }</span></p>
            `}
              />
              <DataCard
                title="Preview"
                description="Preview of how this Team appears inside the app."
                buttonLabel="Open"
                buttonOnClick={() => {
                  setGlobalPreviewOpen(true)
                }}
              />
              <DataCard
                title="Members"
                description="List of all members and administrators of this Team."
                buttonOnClick={() => {
                  setAnimation(false)
                  setTimeout(() => {
                    navigate(window.location.pathname + "/members")
                  }, 250)
                }}
              />
              <DataCard
                title="Report"
                description="Report of this Team."
                buttonOnClick={() => {
                  setAnimation(false)
                  setTimeout(() => {
                    navigate(window.location.pathname + "/reportnew")
                  }, 250)
                }}
              />
              {user &&
              (user.email === "alessiomassaglia@gmail.com" ||
                user.email === "roberto.s@aworld.org" ||
                user.email === "mariavittoria.drp@aworld.org" ||
                user.email === "simone.e@aworld.org") ? (
                <DataCard
                  title="Old Report"
                  description="Old Looker Studio report of this Team."
                  buttonOnClick={() => {
                    setAnimation(false)
                    setTimeout(() => {
                      navigate(window.location.pathname + "/report")
                    }, 250)
                  }}
                />
              ) : null}
            </Stack>
            <Typography variant="h6" className="card-title">
              Configuration
            </Typography>
            <Stack direction="row" spacing={1}>
              <TextField
                fullWidth
                size="small"
                label="Name"
                value={currentTeam.name}
                onChange={(e) => {
                  currentTeam.name = e.target.value
                  setCurrentTeam({ ...currentTeam })

                  // errors check
                  if (!e.target.value) {
                    setNameError(true)
                  } else {
                    setNameError(false)
                  }
                }}
                disabled={!editMode}
                error={nameError}
                helperText={nameError ? "This field is required" : ""}
              />
              <TextField
                fullWidth
                size="small"
                label="UID"
                value={currentTeam.uid}
                InputProps={{ readOnly: true }}
                disabled={!editMode}
              />
              <TextField
                fullWidth
                size="small"
                label="Old ID"
                value={currentTeam.oldId ?? "none"}
                InputProps={{
                  readOnly: true,
                  style: { color: currentTeam.oldId ? undefined : "#939EAA" },
                }}
                disabled={!editMode}
              />
            </Stack>
            <Stack direction="row" spacing={1}>
              <FormControl fullWidth size="small">
                <InputLabel id="access-type">Access Type</InputLabel>
                <Select
                  labelId="access-type"
                  label="Access Type"
                  value={currentTeam.accessType}
                  onChange={(e) => {
                    currentTeam.accessType = e.target.value as TeamAccessType
                    setCurrentTeam({ ...currentTeam })
                  }}
                >
                  {enumAsArray(TeamAccessType).map((item: string, index) => (
                    <MenuItem key={index} value={item}>
                      {item === TeamAccessType.public
                        ? "Public"
                        : item === TeamAccessType.sso
                        ? "SSO"
                        : item === TeamAccessType.activationCode
                        ? "Activation Code"
                        : capitalizeFirstCharacter(item)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth size="small" disabled={!editMode}>
                <InputLabel id="is-primary-select">Primary</InputLabel>
                <Select
                  labelId="is-primary-select"
                  label="Primary"
                  value={currentTeam.isPrimary ? "yes" : "no"}
                  onChange={() => {
                    setIsPrimaryAlertOpen(true)
                  }}
                >
                  <MenuItem value="yes">Yes</MenuItem>
                  <MenuItem value="no">No</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <Stack direction="row" spacing={1}>
              <FormControl
                fullWidth
                size="small"
                disabled={!editMode}
                style={{ position: "relative" }}
              >
                <InputLabel id="stage">Stage</InputLabel>
                <Select
                  labelId="stage"
                  label="Stage"
                  value={currentTeam.stage}
                  onChange={(e) => {
                    // check if the current team contract has sellingPrice and targetMembers set
                    const currentContract = currentTeamContractsList.find(
                      (item) =>
                        Math.max(
                          ...currentTeamContractsList.map((item) =>
                            new Date(item.activatedAt).getTime()
                          )
                        ) === new Date(item.activatedAt).getTime()
                    )

                    if (
                      (currentContract &&
                        currentContract.sellingPrice &&
                        currentContract.targetMembers) ||
                      e.target.value === TeamStage.backstage
                    ) {
                      currentTeam.stage = e.target.value as TeamStage
                      setCurrentTeam({ ...currentTeam })
                    } else {
                      setContractIncompleteAlertOpen(true)
                    }
                  }}
                >
                  {enumAsArray(TeamStage).map((item: string, index) => (
                    <MenuItem key={index} value={item}>
                      {capitalizeFirstCharacter(splitCamelCase(item))}
                    </MenuItem>
                  ))}
                </Select>
                <IconButton
                  style={{
                    position: "absolute",
                    top: 4,
                    right: 24,
                  }}
                  onClick={() => {
                    setStageInfoDialogOpen(true)
                  }}
                >
                  <InfoRounded style={{ fontSize: 16 }} />
                </IconButton>
              </FormControl>
              {currentTeam.stage === TeamStage.featured ? (
                <TextField
                  fullWidth
                  size="small"
                  label="Sorting"
                  value={currentTeam.featuredSorting ?? ""}
                  onChange={(e) => {
                    if (e.target.value) {
                      currentTeam.featuredSorting = parseInt(e.target.value)
                    } else {
                      currentTeam.featuredSorting = null
                    }
                    setCurrentTeam({ ...currentTeam })
                  }}
                  type="number"
                  inputMode="numeric"
                />
              ) : null}
              <FormControl
                fullWidth
                size="small"
                disabled={!editMode}
                style={{ position: "relative" }}
              >
                <InputLabel id="is-home-featured-select">
                  Home featured
                </InputLabel>
                <Select
                  labelId="is-home-featured-select"
                  label="Home featured"
                  value={currentTeam.isHomeFeatured ? "yes" : "no"}
                  onChange={(e) => {
                    // check if the current team contract has sellingPrice and targetMembers set and if document has home
                    const currentContract = currentTeamContractsList.find(
                      (item) =>
                        Math.max(
                          ...currentTeamContractsList.map((item) =>
                            new Date(item.activatedAt).getTime()
                          )
                        ) === new Date(item.activatedAt).getTime()
                    )

                    const defaultLangDocument = currentTeam.document.items.find(
                      (item) => item.isDefault
                    )

                    if (
                      (currentContract &&
                        currentContract.sellingPrice &&
                        currentContract.targetMembers &&
                        defaultLangDocument.home &&
                        defaultLangDocument.home.title &&
                        defaultLangDocument.home.subtitle &&
                        defaultLangDocument.home.image) ||
                      e.target.value === "no"
                    ) {
                      setCurrentTeam((current) => {
                        return {
                          ...current,
                          isHomeFeatured: e.target.value === "yes",
                        }
                      })
                    } else {
                      setCannotBeHomeFeaturedAlertOpen(true)
                    }
                  }}
                >
                  <MenuItem value="yes">Yes</MenuItem>
                  <MenuItem value="no">No</MenuItem>
                </Select>
                <IconButton
                  style={{
                    position: "absolute",
                    top: 4,
                    right: 24,
                  }}
                  onClick={() => {
                    setHomeFeaturedInfoDialogOpen(true)
                  }}
                >
                  <InfoRounded style={{ fontSize: 16 }} />
                </IconButton>
              </FormControl>
              {currentTeam.isHomeFeatured ? (
                <TextField
                  fullWidth
                  size="small"
                  label="Home sorting"
                  value={currentTeam.homeFeaturedSorting ?? ""}
                  onChange={(e) => {
                    if (e.target.value) {
                      currentTeam.homeFeaturedSorting = parseInt(e.target.value)
                    } else {
                      currentTeam.homeFeaturedSorting = null
                    }
                    setCurrentTeam({ ...currentTeam })
                  }}
                  type="number"
                  inputMode="numeric"
                />
              ) : null}
            </Stack>
            <Stack direction="row" spacing={1}>
              <FormControl fullWidth size="small" disabled={!editMode}>
                <InputLabel id="organization-type">
                  Organization Type
                </InputLabel>
                <Select
                  labelId="organization-type"
                  label="Organization Type"
                  value={currentTeam.organizationType}
                  onChange={(e) => {
                    currentTeam.organizationType = e.target
                      .value as TeamOrganizationType
                    setCurrentTeam({ ...currentTeam })
                  }}
                >
                  {enumAsArray(TeamOrganizationType).map(
                    (item: string, index) => (
                      <MenuItem key={index} value={item}>
                        {capitalizeFirstCharacter(
                          item
                            .replace(/([a-z0-9])([A-Z])/g, "$1 $2")
                            .toLowerCase()
                        )}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
              <FormControl fullWidth size="small" disabled={!editMode}>
                <InputLabel id="stakeholder-type">Stakeholder Type</InputLabel>
                <Select
                  labelId="stakeholder-type"
                  label="Stakeholder Type"
                  value={currentTeam.stakeholderType}
                  onChange={(e) => {
                    currentTeam.stakeholderType = e.target
                      .value as TeamStakeholderType
                    setCurrentTeam({ ...currentTeam })
                  }}
                >
                  {enumAsArray(TeamStakeholderType).map(
                    (item: string, index) => (
                      <MenuItem key={index} value={item}>
                        {capitalizeFirstCharacter(item)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
              <FormControl fullWidth size="small" disabled={!editMode}>
                <InputLabel id="users-can-leave-select">
                  Users can leave
                </InputLabel>
                <Select
                  labelId="users-can-leave-select"
                  label="Users can leave"
                  value={currentTeam.canLeave ? "yes" : "no"}
                  onChange={(e) => {
                    const currentTeamCopy: Team = deepCopy(currentTeam)
                    if (e.target.value === "yes") {
                      currentTeamCopy.canLeave = true
                    } else {
                      currentTeamCopy.canLeave = false
                    }
                    setCurrentTeam(currentTeamCopy)
                  }}
                >
                  <MenuItem value="yes">Yes</MenuItem>
                  <MenuItem value="no">No</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth size="small" disabled={!editMode}>
                <InputLabel id="suggest-footprint-select">
                  Suggest footprint
                </InputLabel>
                <Select
                  labelId="suggest-footprint-select"
                  label="Suggest footprint"
                  value={currentTeam.suggestFootprint ? "yes" : "no"}
                  onChange={(e) => {
                    const currentTeamCopy: Team = deepCopy(currentTeam)
                    if (e.target.value === "yes") {
                      currentTeamCopy.suggestFootprint = true
                    } else {
                      currentTeamCopy.suggestFootprint = false
                    }
                    setCurrentTeam(currentTeamCopy)
                  }}
                >
                  <MenuItem value="yes">Yes</MenuItem>
                  <MenuItem value="no">No</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <Typography variant="h6" className="card-title">
              Locations
            </Typography>
            <Autocomplete
              loading={countriesControllerLoading}
              disabled={!editMode}
              fullWidth
              blurOnSelect
              size="small"
              value={currentTeam.availableAtLocations ?? []}
              disablePortal
              multiple
              isOptionEqualToValue={(
                option: LocationAutocompleteOption,
                value: LocationAutocompleteOption
              ) => option.id === value.id}
              groupBy={(option: LocationAutocompleteOption) =>
                option.__typename
              }
              options={[
                ...regionsList.map((item) => {
                  return {
                    id: item.id,
                    name: item.name,
                    __typename: "Region",
                  }
                }),
                ...subRegionsList.map((item) => {
                  return {
                    id: item.id,
                    name: item.name,
                    __typename: "SubRegion",
                  }
                }),
                ...countriesList.map((item) => {
                  return {
                    id: item.id,
                    name: item.name,
                    __typename: "Country",
                  }
                }),
              ]}
              getOptionLabel={(option: LocationAutocompleteOption) =>
                option.name
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Available in (empty for everywhere)"
                />
              )}
              onChange={(e: any, newValues: LocationAutocompleteOption[]) => {
                setCurrentTeam((current) => {
                  return {
                    ...current,
                    availableAtLocations: newValues.length ? newValues : null,
                  }
                })
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                )
              }}
              renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    size="small"
                    key={option.id}
                    label={option.name}
                  />
                ))
              }}
            />
            <Autocomplete
              loading={countriesControllerLoading}
              disabled={!editMode}
              fullWidth
              blurOnSelect
              size="small"
              value={currentTeam.restrictedAtLocations ?? []}
              disablePortal
              multiple
              isOptionEqualToValue={(
                option: LocationAutocompleteOption,
                value: LocationAutocompleteOption
              ) => option.id === value.id}
              groupBy={(option: LocationAutocompleteOption) =>
                option.__typename
              }
              options={[
                ...regionsList.map((item) => {
                  return {
                    id: item.id,
                    name: item.name,
                    __typename: "Region",
                  }
                }),
                ...subRegionsList.map((item) => {
                  return {
                    id: item.id,
                    name: item.name,
                    __typename: "SubRegion",
                  }
                }),
                ...countriesList.map((item) => {
                  return {
                    id: item.id,
                    name: item.name,
                    __typename: "Country",
                  }
                }),
              ]}
              getOptionLabel={(option: LocationAutocompleteOption) =>
                option.name
              }
              renderInput={(params) => (
                <TextField {...params} label="Not available in" />
              )}
              onChange={(e: any, newValues: LocationAutocompleteOption[]) => {
                setCurrentTeam((current) => {
                  return {
                    ...current,
                    restrictedAtLocations: newValues.length ? newValues : null,
                  }
                })
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                )
              }}
              renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    size="small"
                    key={option.id}
                    label={option.name}
                  />
                ))
              }}
            />
            <Typography variant="h6" className="card-title">
              Contracts
            </Typography>
            {!currentTeamContractsList.length ? (
              <Typography>No contracts for this Team</Typography>
            ) : null}
            {currentTeamContractsList.length ? (
              <Stack>
                {(showAllContracts
                  ? currentTeamContractsList
                  : currentTeamContractsList.filter(
                      (item) =>
                        Math.max(
                          ...currentTeamContractsList.map((item) =>
                            new Date(item.activatedAt).getTime()
                          )
                        ) === new Date(item.activatedAt).getTime()
                    )
                ).map((item, index, list) => (
                  <TeamContractCard
                    key={item.id}
                    index={index}
                    item={item}
                    current={
                      Math.max(
                        ...currentTeamContractsList.map((item) =>
                          new Date(item.activatedAt).getTime()
                        )
                      ) === new Date(item.activatedAt).getTime()
                    }
                    list={list}
                  />
                ))}
              </Stack>
            ) : null}
            <Stack direction="row" spacing={1}>
              {currentTeamContractsList.length > 1 && (
                <Button
                  fullWidth
                  disabled={!editMode}
                  endIcon={
                    <ChevronLeftRounded
                      style={{
                        transform: showAllContracts
                          ? "rotate(90deg)"
                          : "rotate(-90deg)",
                        transition: "200ms",
                      }}
                    />
                  }
                  variant="outlined"
                  style={{
                    maxHeight: 40,
                    minHeight: 40,
                  }}
                  onClick={() => {
                    setShowAllContracts((current) => !current)
                  }}
                >
                  {showAllContracts ? "Show less" : "Show more"}
                </Button>
              )}
              <Button
                fullWidth
                disabled={!editMode}
                endIcon={<AddCircleRounded />}
                variant="outlined"
                style={{
                  maxHeight: 40,
                  minHeight: 40,
                }}
                onClick={handleAddContractDialogChange}
              >
                Add contract
              </Button>
            </Stack>
            {/* <Typography variant="h6" className="card-title">
              Visibility
            </Typography> */}
            {/* <Stack direction="row" spacing={1}>
              <FormControl fullWidth size="small" disabled={!editMode}>
                <InputLabel id="leaderboard-visibility">Leaderboard</InputLabel>
                <Select
                  labelId="leaderboard-visibility"
                  label="Leaderboard"
                  value={currentTeam.visibilityOptions.leaderboard}
                  onChange={(e) => {
                    currentTeam.visibilityOptions.leaderboard = e.target
                      .value as TeamVisibilityOptionType
                    setCurrentTeam({ ...currentTeam })
                  }}
                >
                  {enumAsArray(TeamVisibilityOptionType).map(
                    (item: string, index) => (
                      <MenuItem key={index} value={item}>
                        {capitalizeFirstCharacter(item)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
              <FormControl fullWidth size="small" disabled={!editMode}>
                <InputLabel id="members-visibility">Members List</InputLabel>
                <Select
                  labelId="members-visibility"
                  label="Members List"
                  value={currentTeam.visibilityOptions.members}
                  onChange={(e) => {
                    currentTeam.visibilityOptions.members = e.target
                      .value as TeamVisibilityOptionType
                    setCurrentTeam({ ...currentTeam })
                  }}
                >
                  {enumAsArray(TeamVisibilityOptionType).map(
                    (item: string, index) => (
                      <MenuItem key={index} value={item}>
                        {capitalizeFirstCharacter(item)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
              <FormControl fullWidth size="small" disabled={!editMode}>
                <InputLabel id="metric-visibility">Metrics</InputLabel>
                <Select
                  labelId="metric-visibility"
                  label="Metrics"
                  value={currentTeam.visibilityOptions.metric}
                  onChange={(e) => {
                    currentTeam.visibilityOptions.metric = e.target
                      .value as TeamVisibilityOptionType
                    setCurrentTeam({ ...currentTeam })
                  }}
                >
                  {enumAsArray(TeamVisibilityOptionType).map(
                    (item: string, index) => (
                      <MenuItem key={index} value={item}>
                        {capitalizeFirstCharacter(item)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Stack> */}
            <Stack spacing={2}>
              <Typography variant="h6" className="card-title">
                Activation codes
              </Typography>
              <Stack>
                {!currentTeam.accessCodes.filter(
                  (item) => item.groupId === "group_default"
                ).length ? (
                  <Typography>No activation codes for this Team</Typography>
                ) : null}
                {currentTeam.accessCodes.filter(
                  (item) => item.groupId === "group_default"
                ).length
                  ? currentTeam.accessCodes
                      .filter((item) => item.groupId === "group_default")
                      .slice(0, 3)
                      .map((item, index) => (
                        <Card
                          key={item.code}
                          variant="outlined"
                          sx={{
                            boxShadow: "none",
                            borderTopLeftRadius: index === 0 ? undefined : 0,
                            borderTopRightRadius: index === 0 ? undefined : 0,
                            borderBottomLeftRadius:
                              index ===
                              currentTeam.accessCodes
                                .filter(
                                  (item) => item.groupId === "group_default"
                                )
                                .slice(0, 3).length -
                                1
                                ? undefined
                                : 0,
                            borderBottomRightRadius:
                              index ===
                              currentTeam.accessCodes
                                .filter(
                                  (item) => item.groupId === "group_default"
                                )
                                .slice(0, 3).length -
                                1
                                ? undefined
                                : 0,
                            borderTop: index === 0 ? undefined : "none",
                          }}
                        >
                          <CardContent
                            style={{
                              position: "relative",
                              paddingTop: 10,
                              paddingBottom: 10,
                            }}
                          >
                            <Typography style={{ marginTop: 1 }}>
                              {item.code}
                            </Typography>
                            <IconButton
                              sx={{
                                width: 40,
                                height: 40,
                              }}
                              onClick={(e) => {
                                e.stopPropagation()
                                copyTextToClipboard(item.code)
                                setFeedbackOpen(true)
                              }}
                              style={{
                                position: "absolute",
                                right: 65.5,
                                top: 2.5,
                              }}
                            >
                              <ContentCopyRounded sx={{ fontSize: 20 }} />
                            </IconButton>
                            <IconButton
                              sx={{
                                width: 40,
                                height: 40,
                              }}
                              color="error"
                              onClick={(e) => {
                                e.stopPropagation()
                                setCodeToDelete(item.code)
                                handleDeleteCodeDialogChange()
                              }}
                              style={{
                                position: "absolute",
                                right: 11.5,
                                top: 2.5,
                              }}
                            >
                              <DeleteForeverRounded style={{ width: 24 }} />
                            </IconButton>
                          </CardContent>
                        </Card>
                      ))
                  : null}
              </Stack>
              {currentTeam.accessCodes.filter(
                (item) => item.groupId === "group_default"
              ).length > 3 ? (
                <Button
                  fullWidth
                  disabled={!editMode}
                  endIcon={<ArrowForwardIosRounded style={{ fontSize: 16 }} />}
                  variant="outlined"
                  style={{
                    maxHeight: 40,
                    minHeight: 40,
                  }}
                  onClick={() => {
                    setAnimation(false)
                    setTimeout(() => {
                      navigate(window.location.pathname + "/activationcodes")
                    }, 250)
                  }}
                >
                  View{" "}
                  {currentTeam.accessCodes.filter(
                    (item) => item.groupId === "group_default"
                  ).length - 3}{" "}
                  more
                </Button>
              ) : null}
              <Button
                fullWidth
                disabled={!editMode}
                endIcon={<AddCircleRounded />}
                variant="outlined"
                style={{
                  maxHeight: 40,
                  minHeight: 40,
                }}
                onClick={handleActivationCodeDialogChange}
              >
                Add activation code
              </Button>
            </Stack>
          </Stack>
        ) : selectedSwitchPage === 1 ? (
          <Stack spacing={2}>
            <Typography variant="h6" className="card-title">
              Groups
            </Typography>
            {!currentTeam.groups.length ? (
              <Typography>No groups for this Team</Typography>
            ) : null}
            {currentTeam.groups.length ? (
              <Stack>
                <Card
                  style={{
                    border: "1px solid #E5E8EB",
                    borderBottomLeftRadius: !groupsListMemo.length
                      ? undefined
                      : 0,
                    borderBottomRightRadius: !groupsListMemo.length
                      ? undefined
                      : 0,
                    borderBottom: !groupsListMemo.length
                      ? "1px solid #E5E8EB"
                      : "none",
                    boxShadow: "none",
                  }}
                >
                  <CardContent style={{ padding: "12px 24px" }}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Search Groups"
                      value={searchGroupsValue}
                      onChange={(e) => {
                        setSearchGroupsValue(e.target.value)
                      }}
                      InputProps={{
                        endAdornment: searchGroupsValue.length > 0 && (
                          <InputAdornment position="start">
                            <div
                              style={{
                                marginTop: 6,
                                marginRight: -6,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setSearchGroupsValue("")
                              }}
                            >
                              <CancelRounded style={{ width: 18 }} />
                            </div>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </CardContent>
                </Card>
                {!groupsListMemo.length ? (
                  <Typography style={{ marginTop: 16 }}>
                    No results for "{searchGroupsValue}"
                  </Typography>
                ) : null}
                {groupsListMemo.map((group, index) => (
                  <GroupCard
                    key={group.groupId}
                    group={group}
                    index={index}
                    groupsListMemo={groupsListMemo}
                  />
                ))}
              </Stack>
            ) : null}
            <Button
              fullWidth
              disabled={!editMode}
              endIcon={<AddCircleRounded />}
              variant="outlined"
              style={{
                maxHeight: 40,
                minHeight: 40,
              }}
              onClick={() => {
                const id = "temp-" + generateRandomString(16, "alphanumeric")

                currentTeam.groups.push({
                  name: "New Group",
                  groupId: id,
                })

                setCurrentTeam({ ...currentTeam })
              }}
            >
              Add group
            </Button>
          </Stack>
        ) : selectedSwitchPage === 2 ? (
          <Stack spacing={2}>
            <Typography variant="h6" className="card-title">
              Journeys
            </Typography>
            {!currentTeamJourneysList.length ? (
              <Typography>No Journeys for this Team</Typography>
            ) : (
              <Stack>
                <Card
                  style={{
                    border: "1px solid #E5E8EB",
                    borderBottomLeftRadius: !journeysListMemo.length
                      ? undefined
                      : 0,
                    borderBottomRightRadius: !journeysListMemo.length
                      ? undefined
                      : 0,
                    borderBottom: !journeysListMemo.length
                      ? "1px solid #E5E8EB"
                      : "none",
                    boxShadow: "none",
                  }}
                >
                  <CardContent style={{ padding: "12px 24px" }}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Search Journeys"
                      value={searchJourneysValue}
                      onChange={(e) => {
                        setSearchJourneysValue(e.target.value)
                      }}
                      InputProps={{
                        endAdornment: searchJourneysValue.length > 0 && (
                          <InputAdornment position="start">
                            <div
                              style={{
                                marginTop: 6,
                                marginRight: -6,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setSearchJourneysValue("")
                              }}
                            >
                              <CancelRounded style={{ width: 18 }} />
                            </div>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </CardContent>
                </Card>
                {!journeysListMemo.length ? (
                  <Typography style={{ marginTop: 16 }}>
                    No results for "{searchJourneysValue}"
                  </Typography>
                ) : null}
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="column-1">
                    {(provided) => (
                      <Stack
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {journeysListMemo.map((item, index) => (
                          <Draggable
                            key={item.journey.id.toString()}
                            draggableId={item.journey.id.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <Card
                                key={item.journey.id}
                                style={{
                                  minHeight: 60,
                                  paddingLeft: 14,
                                }}
                                variant="outlined"
                                className="list-item-card"
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                                onClick={() => {
                                  window.open(
                                    window.location.origin +
                                      "/journeys/" +
                                      item.journey.id
                                  )
                                }}
                                sx={{
                                  boxShadow: "none",
                                  borderTopLeftRadius: 0,
                                  borderTopRightRadius: 0,
                                  borderBottomLeftRadius:
                                    index === journeysListMemo.length - 1
                                      ? undefined
                                      : 0,
                                  borderBottomRightRadius:
                                    index === journeysListMemo.length - 1
                                      ? undefined
                                      : 0,
                                  borderTop: index === 0 ? undefined : "none",
                                }}
                              >
                                <Stack
                                  direction="row"
                                  style={{ height: 60, position: "relative" }}
                                  alignItems="center"
                                  spacing={4}
                                >
                                  <div
                                    style={{
                                      width: 30,
                                      height: 60,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      transition: "100ms",
                                      cursor: "grab",
                                      marginLeft: editMode ? 20 : -31,
                                      marginRight: -8,
                                    }}
                                    {...provided.dragHandleProps}
                                  >
                                    <DragHandleRounded />
                                  </div>
                                  <div
                                    style={{
                                      position: "relative",
                                      width: 48,
                                    }}
                                  >
                                    {item.journey.stage === "PUBLISHED" ||
                                    item.journey.hasSibling ? (
                                      <Chip
                                        size="small"
                                        label="Live"
                                        color="success"
                                        style={{
                                          position: "relative",
                                          zIndex: 1,
                                        }}
                                      />
                                    ) : item.journey.stage === "ARCHIVED" ? (
                                      <Chip
                                        size="small"
                                        label="Archived"
                                        style={{
                                          position: "relative",
                                          zIndex: 1,
                                        }}
                                      />
                                    ) : (
                                      <Chip
                                        size="small"
                                        label="Draft"
                                        color="warning"
                                        style={{
                                          position: "relative",
                                          zIndex: 1,
                                        }}
                                      />
                                    )}
                                    {item.journey.hasSibling ? (
                                      <Chip
                                        size="small"
                                        label="Draft"
                                        color="warning"
                                        style={{
                                          position: "absolute",
                                          left: 1,
                                          top: 1,
                                        }}
                                      />
                                    ) : null}
                                  </div>
                                  <div
                                    style={{
                                      minWidth: 40,
                                      height: 40,
                                      borderRadius: 4,
                                      backgroundImage: `url(${
                                        item.journey.image ?? noImagePlaceholder
                                      })`,
                                      backgroundSize: "cover",
                                      backgroundPosition: "center",
                                    }}
                                  />
                                  <Typography style={{ minWidth: 21 }}>
                                    {item.journey.lang.toUpperCase()}
                                  </Typography>
                                  <Typography
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      width: "50%",
                                    }}
                                  >
                                    {item.journey.title}
                                  </Typography>
                                  <IconButton
                                    disabled={!editMode}
                                    color="error"
                                    style={{
                                      position: "absolute",
                                      right: 12,
                                      top: 9,
                                      zIndex: 1,
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      currentTeamJourneysList.splice(index, 1)
                                      setCurrentTeamJourneysList([
                                        ...currentTeamJourneysList,
                                      ])
                                    }}
                                  >
                                    <DeleteRounded
                                      style={{
                                        width: 24,
                                      }}
                                    />
                                  </IconButton>
                                </Stack>
                              </Card>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </Stack>
                    )}
                  </Droppable>
                </DragDropContext>
              </Stack>
            )}
            <Button
              onClick={() => {
                setJourneysList([])
                setJourneysToFilter(
                  currentTeamJourneysList.map((item) => item.journey)
                )
                setAddJourneysDialogOpen(true)
              }}
              variant="outlined"
              endIcon={<AddCircleRounded />}
              fullWidth
              disabled={!editMode}
              style={{ height: 40 }}
            >
              Add Journeys
            </Button>
          </Stack>
        ) : selectedSwitchPage === 3 ? (
          <Stack spacing={2}>
            <Typography variant="h6" className="card-title">
              News
            </Typography>
            {!currentTeamNewsList.length ? (
              <Typography>No News for this Team</Typography>
            ) : (
              <Stack>
                {currentTeamNewsList.map((news, index) => (
                  <Card
                    key={news.id}
                    style={{
                      minHeight: 60,
                      paddingInline: 24,
                    }}
                    variant="outlined"
                    className="list-item-card"
                    onClick={() => {
                      window.open(window.location.origin + "/news/" + news.id)
                    }}
                    sx={{
                      boxShadow: "none",
                      borderTopLeftRadius: index === 0 ? undefined : 0,
                      borderTopRightRadius: index === 0 ? undefined : 0,
                      borderBottomLeftRadius:
                        index === currentTeamNewsList.length - 1
                          ? undefined
                          : 0,
                      borderBottomRightRadius:
                        index === currentTeamNewsList.length - 1
                          ? undefined
                          : 0,
                      borderTop: index === 0 ? undefined : "none",
                    }}
                  >
                    <Stack
                      direction="row"
                      style={{ height: 60, position: "relative" }}
                      alignItems="center"
                      spacing={4}
                    >
                      <div
                        style={{
                          minWidth: 40,
                          height: 40,
                          borderRadius: 4,
                          backgroundImage: `url(${
                            news.document.items.find((item) => item.isDefault)
                              .image ?? noImagePlaceholder
                          })`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      />
                      <Typography style={{ minWidth: 21 }}>
                        {news.document.items
                          .find((item) => item.isDefault)
                          .lang.toUpperCase()}
                      </Typography>
                      <Typography
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "50%",
                        }}
                      >
                        {
                          news.document.items.find((item) => item.isDefault)
                            .title
                        }
                      </Typography>
                      <IconButton
                        disabled={!editMode}
                        color="error"
                        style={{
                          position: "absolute",
                          right: -12,
                          top: 9,
                          zIndex: 1,
                        }}
                        onClick={(e) => {
                          e.stopPropagation()
                          setNewsToDelete(news)
                          handleDeleteNewsDialogChange()
                        }}
                      >
                        <DeleteForeverRounded
                          style={{
                            width: 24,
                          }}
                        />
                      </IconButton>
                    </Stack>
                  </Card>
                ))}
              </Stack>
            )}
            <Button
              onClick={() => {
                setNewsLoading(false)
                handleCreateNewsDialogChange()
              }}
              variant="outlined"
              endIcon={<AddCircleRounded />}
              fullWidth
              disabled={!editMode}
              style={{ height: 40 }}
            >
              Add News
            </Button>
          </Stack>
        ) : (
          <Stack spacing={2}>
            <Typography variant="h6" className="card-title">
              Challenges
            </Typography>
            {!currentTeamChallengesList.length ? (
              <Typography>No Challenges for this Team</Typography>
            ) : (
              <Stack>
                {currentTeamChallengesList.map((challenge, index) => (
                  <Card
                    key={challenge.id}
                    style={{
                      minHeight: 60,
                      paddingInline: 24,
                    }}
                    variant="outlined"
                    className="list-item-card"
                    onClick={() => {
                      window.open(
                        window.location.origin + "/challenges/" + challenge.id
                      )
                    }}
                    sx={{
                      boxShadow: "none",
                      borderTopLeftRadius: index === 0 ? undefined : 0,
                      borderTopRightRadius: index === 0 ? undefined : 0,
                      borderBottomLeftRadius:
                        index === currentTeamChallengesList.length - 1
                          ? undefined
                          : 0,
                      borderBottomRightRadius:
                        index === currentTeamChallengesList.length - 1
                          ? undefined
                          : 0,
                      borderTop: index === 0 ? undefined : "none",
                    }}
                  >
                    <Stack
                      direction="row"
                      style={{ height: 60, position: "relative" }}
                      alignItems="center"
                      spacing={4}
                    >
                      <div
                        style={{
                          minWidth: 40,
                          height: 40,
                          borderRadius: 4,
                          backgroundImage: `url(${
                            challenge.document.items.find(
                              (item) => item.isDefault
                            ).image ?? noImagePlaceholder
                          })`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      />
                      <Typography style={{ minWidth: 21 }}>
                        {challenge.document.items
                          .find((item) => item.isDefault)
                          .lang.toUpperCase()}
                      </Typography>
                      <Typography
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "40%",
                          maxWidth: "40%",
                        }}
                      >
                        {challenge.name}
                      </Typography>
                      <div style={{ width: 80 }}>
                        {new Date(challenge.startsAt) > new Date() ? (
                          <Chip
                            size="small"
                            label="Not started"
                            color="default"
                          />
                        ) : new Date(challenge.endsAt) >= new Date() ? (
                          <Chip size="small" label="Current" color="success" />
                        ) : (
                          <Chip size="small" label="Ended" color="error" />
                        )}
                      </div>
                      <Stack style={{ gap: -3 }}>
                        <Typography variant="caption">
                          from {parseStringDate(challenge.startsAt!)}
                        </Typography>
                        <Typography variant="caption">
                          to {parseStringDate(challenge.endsAt!)}
                        </Typography>
                      </Stack>
                      <IconButton
                        disabled={!editMode}
                        color="error"
                        style={{
                          position: "absolute",
                          right: -12,
                          top: 9,
                          zIndex: 1,
                        }}
                        onClick={(e) => {
                          e.stopPropagation()
                          setChallengeToDelete(challenge)
                          handleDeleteChallengeDialogChange()
                        }}
                      >
                        <DeleteForeverRounded
                          style={{
                            width: 24,
                          }}
                        />
                      </IconButton>
                    </Stack>
                  </Card>
                ))}
              </Stack>
            )}
            <Button
              onClick={() => {
                setChallengesLoading(false)
                handleCreateChallengeDialogChange()
              }}
              variant="outlined"
              endIcon={<AddCircleRounded />}
              fullWidth
              disabled={!editMode}
              style={{ height: 40 }}
            >
              Add Challenge
            </Button>
          </Stack>
        )}
      </CardContent>
      {/* add journey dialog */}
      <Dialog
        fullScreen
        open={addJourneysDialogOpen}
        onClose={() => {
          setAddJourneysDialogOpen(false)
          setSelectedJourneys([])
          resetJourneysFilters()
        }}
        TransitionComponent={DialogTransition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setAddJourneysDialogOpen(false)
                setSelectedJourneys([])
                resetJourneysFilters()
              }}
              aria-label="close"
            >
              <CloseRounded />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Select Journeys
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => {
                // check if there are draft journey in selection
                for (let i = 0; i < selectedJourneys.length; i++) {
                  if (
                    selectedJourneys[i].stage === "DRAFT" &&
                    !selectedJourneys[i].hasSibling
                  ) {
                    setAddJourneysDialogOpen(false)
                    setSelectedJourneys([])
                    resetJourneysFilters()
                    setTimeout(() => {
                      setCantAddJourneyDialogOpen(true)
                    }, 150)
                    return
                  }
                }

                // check if premium journeys max number has been reached
                if (
                  currentTeamJourneysList.length + selectedJourneys.length >
                  journeysMaxNumber
                ) {
                  setAddJourneysDialogOpen(false)
                  setSelectedJourneys([])
                  resetJourneysFilters()
                  setTimeout(() => {
                    setMaxNumberDialogOpen(true)
                  }, 150)
                  return
                }

                setCurrentTeamJourneysList([
                  ...currentTeamJourneysList,
                  ...selectedJourneys.map((item) => {
                    return {
                      sorting: 0,
                      journey: item,
                    }
                  }),
                ])

                setAddJourneysDialogOpen(false)
                setSelectedJourneys([])
                resetJourneysFilters()
              }}
              disabled={!selectedJourneys.length}
            >
              Select
            </Button>
          </Toolbar>
        </AppBar>
        <Paper style={{ backgroundColor: "#f5f5f5" }}>
          <JourneysList
            forDialog
            forDialogType={["Business"]}
            selectedJourneys={selectedJourneys}
            setSelectedJourneys={setSelectedJourneys}
            journeysToFilter={journeysToFilter}
          />
        </Paper>
      </Dialog>
      {/* can't add journeys dialog */}
      <Dialog
        open={cantAddJourneyDialogOpen}
        onClose={() => {
          setCantAddJourneyDialogOpen(false)
        }}
        aria-labelledby="alert-dialog-title-cant-add-journey"
        aria-describedby="alert-dialog-description-cant-add-journey"
      >
        <DialogTitle id="alert-dialog-title-cant-add-journey">
          These Journeys cannot be added
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-cant-add-journey">
            There are one or more Journeys that are not published
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCantAddJourneyDialogOpen(false)
            }}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {/* can't add journeys for max number dialog */}
      <CantPerformActionDialog
        open={maxNumberDialogOpen}
        setOpen={setMaxNumberDialogOpen}
        content={`The maximum number of Journeys is ${journeysMaxNumber}`}
      />
      {/* add activation code dialog */}
      <Dialog
        open={activationCodeDialogOpen}
        onClose={
          createActivationCodeLoading ? null : handleActivationCodeDialogChange
        }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to add a new activation code to this Team?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            If there are already any, they will not be overwritten
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleActivationCodeDialogChange}
            disabled={createActivationCodeLoading}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={createActivationCodeLoading}
            onClick={async () => {
              const result = await createActivationCode(
                ActivationCodeType.TeamActivationCode,
                ActivationCodePolicy.IterationUnlimited,
                null,
                { teamId: currentTeam.id }
              )
              if (typeof result !== "boolean") {
                currentTeam.accessCodes.push(result)
                setCurrentTeam({ ...currentTeam })
                handleActivationCodeDialogChange()
              }
            }}
            autoFocus
          >
            Add
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {/* delete news dialog */}
      <Dialog
        open={deleteNewsDialogOpen}
        onClose={deletingNews ? null : handleDeleteNewsDialogChange}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to permanently delete this News?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The News "
            {newsToDelete
              ? newsToDelete.document.items.find((item) => item.isDefault).title
              : ""}
            " will be{" "}
            <span style={{ fontWeight: 600 }}>permanently deleted</span> and the
            action is irreversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeleteNewsDialogChange}
            disabled={deletingNews}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={deletingNews}
            onClick={async () => {
              setDeletingNews(true)

              const noErrors = await deleteNews(newsToDelete.id)
              if (noErrors) {
                const indexToRemove = currentTeamNewsList.findIndex(
                  (item) => item.id === newsToDelete.id
                )
                currentTeamNewsList.splice(indexToRemove, 1)
                setCurrentTeamNewsList([...currentTeamNewsList])
                setNewsToDelete(null)
                handleDeleteNewsDialogChange()
              }

              setDeletingNews(false)
            }}
            autoFocus
            color="error"
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {/* delete challenge dialog */}
      <Dialog
        open={deleteChallengeDialogOpen}
        onClose={deletingChallenge ? null : handleDeleteChallengeDialogChange}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to permanently delete this Challenge?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The Challenge "{challengeToDelete ? challengeToDelete.name : ""}"
            will be <span style={{ fontWeight: 600 }}>permanently deleted</span>{" "}
            and the action is irreversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeleteChallengeDialogChange}
            disabled={deletingChallenge}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={deletingChallenge}
            onClick={async () => {
              setDeletingChallenge(true)

              const noErrors = await deleteChallenge(challengeToDelete.id)
              if (noErrors) {
                const indexToRemove = currentTeamChallengesList.findIndex(
                  (item) => item.id === challengeToDelete.id
                )
                currentTeamChallengesList.splice(indexToRemove, 1)
                setCurrentTeamChallengesList([...currentTeamChallengesList])
                setChallengeToDelete(null)
                handleDeleteChallengeDialogChange()
              }

              setDeletingChallenge(false)
            }}
            autoFocus
            color="error"
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {/* delete activation code dialog */}
      <Dialog
        open={deleteCodeDialogOpen}
        onClose={deletingCode ? null : handleDeleteCodeDialogChange}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to permanently delete this activation code?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The activation code{" "}
            <span style={{ fontWeight: 600 }}>{codeToDelete}</span> will be
            permanently deleted and it will no longer be able to be used to
            access the current Team
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeleteCodeDialogChange}
            disabled={deletingCode}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={deletingCode}
            onClick={async () => {
              setDeletingCode(true)

              const noErrors = await deleteActivationCode(codeToDelete)
              if (noErrors) {
                const indexToRemove = currentTeam.accessCodes.findIndex(
                  (item) => item.code === codeToDelete
                )
                currentTeam.accessCodes.splice(indexToRemove, 1)
                setCurrentTeam({ ...currentTeam })

                setCodeToDelete(null)
                handleDeleteCodeDialogChange()
              }

              setDeletingCode(false)
            }}
            autoFocus
            color="error"
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {/* create news for team */}
      <CreateNewsDialog
        dialogOpen={createNewsDialogOpen}
        setDialogOpen={setCreateNewsDialogOpen}
        handleDialogChange={handleCreateNewsDialogChange}
        topBarText="Add News"
        preSelectedTeam={{ id: currentTeam.id, name: currentTeam.name }}
        currentTeamNewsList={currentTeamNewsList}
        setCurrentTeamNewsList={setCurrentTeamNewsList}
      />
      {/* create challenge for team */}
      <CreateChallengeDialog
        dialogOpen={createChallengeDialogOpen}
        setDialogOpen={setCreateChallengeDialogOpen}
        handleDialogChange={handleCreateChallengeDialogChange}
        topBarText="Add Challenge"
        preSelectedTeam={currentTeam}
        currentTeamChallengesList={currentTeamChallengesList}
        setCurrentTeamChallengesList={setCurrentTeamChallengesList}
      />
      {/* create team contract dialog */}
      <CreateContractDialog
        dialogOpen={addContractDialogOpen}
        setDialogOpen={setAddContractDialogOpen}
        handleDialogChange={handleAddContractDialogChange}
      />
      {/* primary change dialog */}
      <Dialog open={isPrimaryAlertOpen} TransitionComponent={DialogTransition}>
        <DialogTitle>Are you sure you want to proceed?</DialogTitle>
        <DialogContent>
          <Typography>
            Although it is possible to reverse this change in the future, the
            operation may take some time to complete and be visible to all team
            members. Are you sure you want to proceed? If you have any doubts,
            contact an administrator.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setIsPrimaryAlertOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setCurrentTeam({
                ...currentTeam,
                isPrimary:
                  currentTeam.isPrimary === null
                    ? true
                    : !currentTeam.isPrimary,
              })
              setIsPrimaryAlertOpen(false)
            }}
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
      {/* cannot set team to live or featured if current contract is missing sellingPrice or targetMembers */}
      <CantPerformActionDialog
        open={contractIncompleteAlertOpen}
        setOpen={setContractIncompleteAlertOpen}
        content='To be able to set the stage of this Team to "Live", "Featured" or "Hidden" you need to add selling price and target members to the current contract.'
      />
      {/* cannot set team to home featured if current contract is missing sellingPrice or targetMembers or home document is missing */}
      <CantPerformActionDialog
        open={cannotBeHomeFeaturedAlertOpen}
        setOpen={setCannotBeHomeFeaturedAlertOpen}
        content='To be able to set the stage of this Team to "Home featured" you need to add selling price and target members to the current contract and set "Home" details for the default language.'
      />
      {/* stage info dialog */}
      <Dialog open={stageInfoDialogOpen} TransitionComponent={DialogTransition}>
        <DialogTitle>What are the stages?</DialogTitle>
        <DialogContent>
          <div style={{ marginLeft: 15 }}>
            <ul>
              <li>
                <strong>Backstage</strong>: the Team is not visible anywhere.
              </li>
              <li>
                <strong>Hidden</strong>: used for integrations; the Team doesn't
                appear in the app.
              </li>
              <li>
                <strong>Live</strong>: the Team is visible in the app.
              </li>
              <li>
                <strong>Featured</strong>: the Team appears in the app's group
                missions section and search suggestions.
              </li>
            </ul>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setStageInfoDialogOpen(false)
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {/* home featured info dialog */}
      <Dialog
        open={homeFeaturedInfoDialogOpen}
        TransitionComponent={DialogTransition}
      >
        <DialogTitle>What does it mean?</DialogTitle>
        <DialogContent>
          <Typography>
            Set this field to "Yes" to make this Team appear in the app's home
            carousel.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setHomeFeaturedInfoDialogOpen(false)
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}

export default TeamGeneralEdit
