import {
  AppBar,
  Button,
  CardContent,
  Dialog,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material"
import { useContext, useMemo, useState } from "react"
import { MainContext } from "../../controllers/main"
import { Stage } from "../../services/config/enum"
import {
  DialogTransition,
  capitalizeFirstCharacter,
  enumAsArray,
} from "../../services/utilities/utility"
import { CloseRounded, OpenInNewRounded } from "@mui/icons-material"
import Team from "../../models/team"
import { TeamsContext } from "../../controllers/teams"
import TeamsList from "../../views/team/teamsList"
import { SurveysContext } from "../../controllers/surveys"
import { isDev } from "../../services/config/constants"
import { SurveyProvider } from "../../models/survey"
import ISODatePicker from "../global/isoDatePicker"
import ISOTimePicker from "../global/isoTimePicker"

const SurveyGeneralEdit = () => {
  const { showPadding } = useContext(MainContext)
  const {
    currentSurvey,
    setCurrentSurvey,
    editMode,
    handleError,
    setHandleError,
  } = useContext(SurveysContext)
  const { resetTeamsFilters } = useContext(TeamsContext)

  // change team dialog
  const [changeTeamDialogOpen, setChangeTeamDialogOpen] =
    useState<boolean>(false)
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null)

  // edit disabled
  const editDisabled = useMemo(
    () => currentSurvey.stage === Stage.PUBLISHED || currentSurvey.hasSibling,
    [currentSurvey]
  )

  // Track date validation errors
  const [dateErrors, setDateErrors] = useState({
    startsAt: false,
    endsAt: false,
  })

  return (
    <CardContent
      style={{
        height:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        maxHeight:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        overflowY: "scroll",
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h6" className="card-title">
          Configuration
        </Typography>
        <Stack direction="row" gap={2}>
          <TextField
            fullWidth
            size="small"
            label="Handle"
            disabled={!editMode}
            value={currentSurvey.handle}
            onChange={(e) => {
              setCurrentSurvey({
                ...currentSurvey,
                handle: e.target.value,
              })

              // errors check
              if (!e.target.value) {
                setHandleError(true)
              } else {
                setHandleError(false)
              }
            }}
            error={handleError}
            helperText={handleError ? "This field is required" : ""}
          />
          <TextField
            fullWidth
            size="small"
            label="Project ID"
            disabled={!editMode || editDisabled}
            value={currentSurvey.projectId}
            onChange={(e) => {
              setCurrentSurvey({
                ...currentSurvey,
                projectId: e.target.value,
              })
            }}
          />
        </Stack>
        <FormControl
          fullWidth
          size="small"
          disabled={!editMode || editDisabled}
        >
          <InputLabel id="provider">Provider</InputLabel>
          <Select
            labelId="provider"
            label="Provider"
            value={currentSurvey.provider}
            onChange={(e) => {
              const newProvider = e.target.value as SurveyProvider
              setCurrentSurvey({
                ...currentSurvey,
                provider: newProvider,
                useSecret:
                  newProvider === SurveyProvider.typeform ? true : false,
              })
            }}
          >
            {enumAsArray(SurveyProvider).map((item: string, index) => (
              <MenuItem key={index} value={item}>
                {capitalizeFirstCharacter(item.toLowerCase())}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography variant="h6" className="card-title">
          Duration
        </Typography>
        <Stack direction="row" spacing={2}>
          <ISODatePicker
            label="Start date"
            date={currentSurvey.startsAt}
            setDate={(newDate: string) => {
              const newStartsAt = newDate
                ? newDate.split("T")[0] +
                  "T" +
                  (currentSurvey.startsAt
                    ? currentSurvey.startsAt.split("T")[1]
                    : "12:00:00.000Z")
                : newDate
              setCurrentSurvey({
                ...currentSurvey,
                startsAt: newStartsAt,
              })
              setDateErrors((prev) => ({
                ...prev,
                startsAt: !newStartsAt,
              }))
            }}
            error={dateErrors.startsAt}
            disabled={!editMode}
            hour={12}
          />
          <ISOTimePicker
            label="Start time"
            time={currentSurvey.startsAt}
            setTime={(newTime: string) => {
              setCurrentSurvey({
                ...currentSurvey,
                startsAt: newTime,
              })
            }}
            error={dateErrors.startsAt}
            disabled={!editMode}
          />
        </Stack>
        <Typography
          style={{
            lineHeight: 1.5,
            fontSize: "0.75rem",
            color: "#667380",
            marginLeft: 14,
            marginTop: 6,
          }}
        >
          {currentSurvey.startsAt &&
            `The Survey will start on ${new Date(
              currentSurvey.startsAt
            ).toLocaleDateString()} at ${new Date(
              currentSurvey.startsAt
            ).toLocaleTimeString("it", {
              hour12: true,
            })} in local time, ${new Date(
              new Date(currentSurvey.startsAt).getTime() +
                new Date().getTimezoneOffset() * 60000
            ).toLocaleDateString()} at ${new Date(
              new Date(currentSurvey.startsAt).getTime() +
                new Date().getTimezoneOffset() * 60000
            ).toLocaleTimeString("it", {
              hour12: true,
            })} UTC`}
        </Typography>
        <Stack direction="row" spacing={2}>
          <ISODatePicker
            label="End date"
            date={currentSurvey.endsAt}
            setDate={(newDate: string) => {
              const newEndsAt = newDate
                ? newDate.split("T")[0] +
                  "T" +
                  (currentSurvey.endsAt
                    ? currentSurvey.endsAt.split("T")[1]
                    : "12:00:00.000Z")
                : newDate
              setCurrentSurvey({
                ...currentSurvey,
                endsAt: newEndsAt,
              })
              setDateErrors((prev) => ({
                ...prev,
                endsAt:
                  !newEndsAt ||
                  (currentSurvey.startsAt &&
                    newEndsAt <= currentSurvey.startsAt),
              }))
            }}
            error={dateErrors.endsAt}
            minDate={currentSurvey.startsAt}
            disabled={!editMode}
            hour={12}
          />
          <ISOTimePicker
            label="End time"
            time={currentSurvey.endsAt}
            setTime={(newTime: string) => {
              setCurrentSurvey({
                ...currentSurvey,
                endsAt: newTime,
              })
            }}
            error={dateErrors.endsAt}
            disabled={!editMode}
          />
        </Stack>
        <Typography
          style={{
            lineHeight: 1.5,
            fontSize: "0.75rem",
            color: "#667380",
            marginLeft: 14,
            marginTop: 6,
          }}
        >
          {currentSurvey.endsAt &&
            `The Survey will end on ${new Date(
              currentSurvey.endsAt
            ).toLocaleDateString()} at ${new Date(
              currentSurvey.endsAt
            ).toLocaleTimeString("it", {
              hour12: true,
            })} in local time, ${new Date(
              new Date(currentSurvey.endsAt).getTime() +
                new Date().getTimezoneOffset() * 60000
            ).toLocaleDateString()} at ${new Date(
              new Date(currentSurvey.endsAt).getTime() +
                new Date().getTimezoneOffset() * 60000
            ).toLocaleTimeString("it", {
              hour12: true,
            })} UTC`}
        </Typography>
        <TextField
          disabled={!editMode || editDisabled}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <Stack direction="row" spacing={0}>
                {currentSurvey.team?.id !== "team_default" && (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation()
                      if (isDev) {
                        window.open(
                          window.location.protocol +
                            "//" +
                            window.location.host +
                            "/teams/" +
                            currentSurvey.team.id
                        )
                      } else {
                        window.open(
                          window.location.protocol +
                            "//" +
                            window.location.host +
                            "/teams/" +
                            currentSurvey.team.id
                        )
                      }
                    }}
                  >
                    <OpenInNewRounded
                      style={{
                        fontSize: 15,
                        opacity: 0.9,
                      }}
                    />
                  </Button>
                )}
                <Button
                  sx={{ paddingInline: 2 }}
                  onClick={() => {
                    setChangeTeamDialogOpen(true)
                  }}
                  disabled={!editMode || editDisabled}
                >
                  Change
                </Button>
                {currentSurvey.team?.id !== "team_default" && (
                  <Button
                    sx={{ paddingInline: 2 }}
                    onClick={() => {
                      setCurrentSurvey({
                        ...currentSurvey,
                        team: {
                          id: "team_default",
                          document: null,
                        },
                      })
                    }}
                    disabled={!editMode || editDisabled}
                  >
                    Reset
                  </Button>
                )}
              </Stack>
            ),
          }}
          fullWidth
          label="Team"
          size="small"
          value={
            currentSurvey.team?.id !== "team_default"
              ? currentSurvey.team?.document?.items?.find(
                  (item) => item.isDefault
                )?.title
              : "Global (App)"
          }
        />
        <Stack spacing={1}>
          <FormControl fullWidth size="small">
            <FormControlLabel
              control={
                <Switch
                  disabled={!editMode}
                  checked={currentSurvey.storeResponses}
                  onChange={(e) => {
                    setCurrentSurvey({
                      ...currentSurvey,
                      storeResponses: e.target.checked,
                    })
                  }}
                />
              }
              label="Store Responses"
            />
          </FormControl>
          {currentSurvey.provider === SurveyProvider.typeform && (
            <FormControl fullWidth size="small">
              <FormControlLabel
                control={
                  <Switch
                    disabled={!editMode}
                    checked={currentSurvey.useSecret}
                    onChange={(e) => {
                      setCurrentSurvey({
                        ...currentSurvey,
                        useSecret: e.target.checked,
                      })
                    }}
                  />
                }
                label="Use Secret for Webhook"
              />
            </FormControl>
          )}
        </Stack>
      </Stack>
      {/* change team dialog */}
      <Dialog
        fullScreen
        open={changeTeamDialogOpen}
        onClose={() => {
          setChangeTeamDialogOpen(false)
          setSelectedTeam(null)
          resetTeamsFilters()
        }}
        TransitionComponent={DialogTransition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setChangeTeamDialogOpen(false)
                setSelectedTeam(null)
                resetTeamsFilters()
              }}
              aria-label="close"
            >
              <CloseRounded />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Select Team
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => {
                setCurrentSurvey({
                  ...currentSurvey,
                  team: selectedTeam,
                })

                setChangeTeamDialogOpen(false)
                setSelectedTeam(null)
                resetTeamsFilters()
              }}
              disabled={!selectedTeam}
            >
              Select
            </Button>
          </Toolbar>
        </AppBar>
        <Paper style={{ backgroundColor: "#f5f5f5" }}>
          <TeamsList
            forDialog
            selectedTeam={selectedTeam}
            setSelectedTeam={setSelectedTeam}
          />
        </Paper>
      </Dialog>
    </CardContent>
  )
}

export default SurveyGeneralEdit
