import {
  ButtonBase,
  Chip,
  LinearProgress,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material"
import { useCallback, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import ListContainer from "../../components/global/listContainer"
import ListTable from "../../components/global/listTable"
import TableSpinner from "../../components/global/tableSpinner"
import { MainContext } from "../../controllers/main"
import {
  calculateTimeElapsed,
  capitalizeFirstCharacter,
  registerAnalyticsEvent,
} from "../../services/utilities/utility"
import { SurveysContext } from "../../controllers/surveys"
import { SurveysListTopBar, CreateSurveyDialog } from "../../components/survey"
import CopyToClipboardButton from "../../components/global/copyToClipboardButton"
import TranslationsStack from "../../components/global/translationsStack"
import { OpenInNewRounded } from "@mui/icons-material"

const SurveysList = () => {
  const { setAnimation, showPadding, cmdPressed } = useContext(MainContext)
  const {
    loading,
    surveysList,
    surveysListNextToken,
    getSurveysList,
    searchSurveysList,
    loadMoreSurveys,
    hasSearched,
    updatingList,
    setUpdatingList,
    searchHandle,
    setSearchHandle,
    searchProvider,
    searchStage,
    searchSuspended,
  } = useContext(SurveysContext)
  const navigate = useNavigate()

  // list background update
  const backgroundUpdate = () => {
    if (!surveysList.length) {
      getSurveysList()
    } else if (!loading && !hasSearched) {
      setUpdatingList(true)
      getSurveysList(false)
    }
  }

  useEffect(() => {
    backgroundUpdate()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // handle create survey dialog
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const handleDialogChange = useCallback(() => {
    setDialogOpen(!dialogOpen)
  }, [dialogOpen])

  // load more button loading
  const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false)

  // register analytics event
  useEffect(() => {
    registerAnalyticsEvent({ type: "page_view", name: "surveys_list" })
  }, [])

  return (
    <ListContainer style={{ position: "relative" }}>
      {updatingList && (
        <LinearProgress
          style={{
            position: "absolute",
            top: 143,
            width: "100%",
            zIndex: 10,
          }}
        />
      )}
      <SurveysListTopBar
        loading={loading}
        textFieldValue={searchHandle}
        textFieldPlaceholder="Search Surveys"
        addButtonLabel="Add Survey"
        textFieldOnChange={(e) => {
          setSearchHandle(e.target.value)
          if (e.target.value.length === 0 && hasSearched) {
            setUpdatingList(true)

            if (!searchProvider && !searchStage && searchSuspended === null) {
              getSurveysList(false)
            } else {
              searchSurveysList()
            }
          }
        }}
        textFieldOnKeyDown={(e) => {
          if (e.key === "Enter" && searchHandle.length > 2) {
            searchSurveysList()
          }
        }}
        cancelButtonOnClick={() => {
          setSearchHandle("")
          if (hasSearched) {
            setUpdatingList(true)

            if (!searchProvider && !searchStage && searchSuspended === null) {
              getSurveysList(false)
            } else {
              searchSurveysList()
            }
          }
        }}
        searchButtonOnClick={() => {
          searchSurveysList()
        }}
        addButtonOnClick={handleDialogChange}
      />
      {loading ? (
        <TableSpinner
          height={
            showPadding === "yes"
              ? "calc(100vh - 208px)"
              : "calc(100vh - 175px)"
          }
        />
      ) : (
        <ListTable
          height={
            showPadding === "yes"
              ? "calc(100vh - 208px)"
              : "calc(100vh - 175px)"
          }
          headingItems={[
            "Stage",
            "Handle & ID",
            "Translations",
            "Time Status",
            "Provider",
            "Team",
            "Updated",
          ]}
          nextToken={surveysListNextToken}
          loadingMoreItems={loadMoreLoading}
          setLoadingMoreItems={setLoadMoreLoading}
          loadMore={
            !searchHandle &&
            !searchProvider &&
            !searchStage &&
            searchSuspended === null
              ? loadMoreSurveys
              : async () => {
                  const result = await searchSurveysList(false, true)
                  return result
                }
          }
          tableBody={surveysList.map((item) => (
            <TableRow
              key={item.id}
              hover
              style={{
                cursor: "pointer",
              }}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
              onClick={() => {
                if (cmdPressed) {
                  window.open(`${window.location.href}/${item.id}`)
                } else {
                  setAnimation(false)
                  setTimeout(() => {
                    navigate(`/surveys/${item.id}`)
                  }, 250)
                }
              }}
            >
              {item.suspended ? (
                <TableCell>
                  <Chip size="small" label="Suspended" color="error" />
                </TableCell>
              ) : (
                <TableCell style={{ position: "relative" }}>
                  {item.stage === "PUBLISHED" || item.hasSibling ? (
                    <Chip
                      size="small"
                      label="Live"
                      color="success"
                      style={{ position: "relative", zIndex: 1 }}
                    />
                  ) : item.stage === "ARCHIVED" ? (
                    <Chip
                      size="small"
                      label="Archived"
                      style={{ position: "relative", zIndex: 1 }}
                    />
                  ) : (
                    <Chip
                      size="small"
                      label="Draft"
                      color="warning"
                      style={{ position: "relative", zIndex: 1 }}
                    />
                  )}
                  {item.hasSibling ? (
                    <Chip
                      size="small"
                      label="Draft"
                      color="warning"
                      style={{ position: "absolute", left: 18 }}
                    />
                  ) : null}
                </TableCell>
              )}
              <TableCell>
                <Stack sx={{ gap: -5 }}>
                  <Typography
                    style={{
                      fontSize: 15,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: 320,
                    }}
                  >
                    {item.handle}
                  </Typography>
                  <Stack
                    direction="row"
                    alignItems="center"
                    style={{ marginLeft: -3 }}
                  >
                    <CopyToClipboardButton textToCopy={item.id} size="small" />
                    <Typography
                      variant="caption"
                      style={{
                        fontSize: 10,
                        color: "gray",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item.id}
                    </Typography>
                  </Stack>
                </Stack>
              </TableCell>
              <TableCell>
                <TranslationsStack
                  translations={item.document.items.map((item) => ({
                    lang: item.lang,
                  }))}
                />
              </TableCell>
              <TableCell>
                {new Date(item.startsAt) > new Date() ? (
                  <Chip size="small" label="Not Started" color="default" />
                ) : new Date(item.endsAt) >= new Date() ? (
                  <Chip size="small" label="Current" color="success" />
                ) : (
                  <Chip size="small" label="Ended" color="error" />
                )}
              </TableCell>
              <TableCell>
                <Chip
                  label={capitalizeFirstCharacter(item.provider.toLowerCase())}
                  size="small"
                />
              </TableCell>
              <TableCell>
                <ButtonBase
                  disableRipple
                  disabled={!item.team?.id}
                  onClick={(e) => {
                    e.stopPropagation()
                    window.open(`/teams/${item.team.id}`)
                  }}
                >
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Typography
                      variant="caption"
                      style={{
                        opacity: !item.team?.id ? 0.2 : 1,
                      }}
                    >
                      {!item.team?.id
                        ? "Global (App)"
                        : item.team.document.items.find(
                            (item) => item.isDefault
                          ).title}
                    </Typography>
                    {item.team?.id && (
                      <OpenInNewRounded
                        style={{ fontSize: 15, opacity: 0.9 }}
                      />
                    )}
                  </Stack>
                </ButtonBase>
              </TableCell>
              <TableCell style={{ minWidth: 120 }}>
                <Typography variant="caption">
                  {calculateTimeElapsed(item.updatedAt)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        />
      )}
      <CreateSurveyDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        handleDialogChange={handleDialogChange}
      />
    </ListContainer>
  )
}

export default SurveysList
