import { styled, Theme, CSSObject } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import MuiDrawer from "@mui/material/Drawer"
import Box from "@mui/material/Box"
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import MenuIcon from "@mui/icons-material/Menu"
import { useContext, useEffect, useState } from "react"
import { MainContext } from "./controllers/main"
import { CircularProgress } from "@mui/material"
import { useLocation } from "react-router-dom"
import {
  configureAmplify,
  signOut,
  checkAuth as authCheck,
} from "./services/server/auth"
import SignIn from "./views/global/signIn"
import AppRoutes from "./services/navigation/routes"
import { EpisodesController } from "./controllers/episodes"
import { Compose } from "./services/utilities/utility"
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  concat,
} from "@apollo/client"
import Avatar from "@mui/material/Avatar"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import Logout from "@mui/icons-material/Logout"
import { MediaController } from "./controllers/media"
import { mobileWidthBreakpoint } from "./services/config/constants"
import { JourneysController } from "./controllers/journeys"
import { TeamJourneysRelationsController } from "./controllers/teamJourneysRelations"
import { LanguagesContext, LanguagesController } from "./controllers/languages"
import { DailyEpisodesController } from "./controllers/dailyEpisodes"
import { fetchAuthSession } from "aws-amplify/auth"
import { setContext } from "@apollo/link-context"
import { ChannelsController } from "./controllers/channels"
import { NewsController } from "./controllers/news"
import { ActionsController } from "./controllers/actions"
import { CurrentNewsController } from "./controllers/currentNews"
import { CountriesController } from "./controllers/countries"
import { ActionGroupsController } from "./controllers/actionGroups"
import { BadgesController } from "./controllers/badges"
import { TagsController } from "./controllers/tags"
import { ChallengesController } from "./controllers/challenges"
import { MetricsController } from "./controllers/metrics"
import { FootprintQuestionsController } from "./controllers/footprintQuestions"
import MobileDrawer from "./components/drawer/mobileDrawer"
import DesktopDrawer from "./components/drawer/desktopDrawer"
import { TranslationsController } from "./controllers/translations"
import { TeamsController } from "./controllers/teams"
import { ActivationCodesController } from "./controllers/activationCodes"
import { MarketplaceController } from "./controllers/marketplace"
import { NftsController } from "./controllers/nfts"
import { NotificationsController } from "./controllers/notifications"
import { MissionsController } from "./controllers/missions"
import { LeaguesController } from "./controllers/leagues"
import { SurveysController } from "./controllers/surveys"

const drawerWidth: number = 210

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

// apollo client
const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_ENDPOINT })

const authLink = setContext(async (_, { headers }) => {
  const session = await fetchAuthSession()
  const token = session.tokens.accessToken

  return {
    headers: {
      ...headers,
      authorization: token || null,
    },
  }
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authLink, httpLink),
})

// top app bar
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

// drawer
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    flexShrink: 0,
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  },
}))

const Navigation = () => {
  const {
    drawerOpen,
    toggleDrawer,
    isAuth,
    setIsAuth,
    setAnimation,
    windowWidth,
  } = useContext(MainContext)
  const location = useLocation()

  // controllers
  const controllers = [
    ActivationCodesController,
    FootprintQuestionsController,
    MetricsController,
    TagsController,
    CountriesController,
    TranslationsController,
    EpisodesController,
    DailyEpisodesController,
    JourneysController,
    TeamJourneysRelationsController,
    MediaController,
    ChannelsController,
    TeamsController,
    NewsController,
    CurrentNewsController,
    ActionsController,
    ActionGroupsController,
    BadgesController,
    ChallengesController,
    MarketplaceController,
    NftsController,
    MissionsController,
    SurveysController,
    ...(process.env.REACT_APP_ENV === "dev" ? [NotificationsController] : []),
    LeaguesController,
  ]

  // check auth every time the route changes
  useEffect(() => {
    const checkAuth = async () => {
      try {
        await authCheck()
        setIsAuth(true)
      } catch {
        let showPadding = localStorage.getItem("showPadding")
        let colorCombination = localStorage.getItem("colorCombination")
        let drawerOpen = localStorage.getItem("drawerOpen")
        let showAdvancedOption = localStorage.getItem("showAdvancedOption")
        localStorage.clear()
        if (showPadding) {
          localStorage.setItem("showPadding", showPadding)
        }
        if (colorCombination) {
          localStorage.setItem("colorCombination", colorCombination)
        }
        if (drawerOpen) {
          localStorage.setItem("drawerOpen", drawerOpen)
        }
        if (showAdvancedOption) {
          localStorage.setItem("showAdvancedOption", showAdvancedOption)
        }
        setIsAuth(false)
      }
    }

    configureAmplify()
    checkAuth()
    setAnimation(true)
  }, [location, setAnimation, setIsAuth])

  return isAuth === undefined ? null : isAuth ? (
    <ApolloProvider client={client}>
      <Box sx={{ display: "flex" }}>
        {/* MUI component  */}
        <CssBaseline />
        <AppBar
          position="absolute"
          open={windowWidth < mobileWidthBreakpoint ? false : drawerOpen}
        >
          <Toolbar
            sx={{
              pr: "24px",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => {
                toggleDrawer()
              }}
              sx={{
                marginRight: "36px",
                ...(drawerOpen && windowWidth >= mobileWidthBreakpoint
                  ? { display: "none" }
                  : null),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {location.pathname === "/"
                ? "Dashboard"
                : location.pathname.includes("/teamsjourneys")
                ? "Business release"
                : location.pathname.includes("/freejourneys")
                ? "Free release"
                : location.pathname.includes("/dailyepisodes")
                ? "Daily Episodes"
                : location.pathname.includes("/nftcatalogs")
                ? "NFT catalogs"
                : location.pathname.includes("/activationcodes")
                ? "Activation codes"
                : location.pathname.includes("/medialibrary")
                ? "Media library"
                : location.pathname.includes("/currentnews")
                ? "Current News"
                : location.pathname.slice(2).includes("/")
                ? location.pathname.charAt(1).toUpperCase() +
                  location.pathname.slice(
                    2,
                    location.pathname.slice(2).indexOf("/") + 2
                  )
                : location.pathname.charAt(1).toUpperCase() +
                  location.pathname.slice(2)}
            </Typography>
            <AccountMenu />
          </Toolbar>
        </AppBar>
        {windowWidth < mobileWidthBreakpoint ? (
          <MobileDrawer />
        ) : (
          <DesktopDrawer />
        )}
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
            paddingTop: 8,
            paddingBottom: 0,
          }}
        >
          <LanguagesController>
            <LanguagesContext.Consumer>
              {(context) =>
                context.loadingLanguages ? (
                  <div
                    style={{
                      width: window.innerWidth,
                      height: window.innerHeight,
                      backgroundColor: "white",
                      position: "fixed",
                      top: 0,
                      left: 0,
                      zIndex: 9999999,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress color="primary" />
                  </div>
                ) : (
                  <Compose components={controllers}>
                    <AppRoutes />
                  </Compose>
                )
              }
            </LanguagesContext.Consumer>
          </LanguagesController>
        </Box>
      </Box>
    </ApolloProvider>
  ) : (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <SignIn />
    </Box>
  )
}

export default Navigation

function AccountMenu() {
  const { setIsAuth, user } = useContext(MainContext)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  // sign out
  const signOutNotifyer = async () => {
    await signOut()
    setIsAuth(false)
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar sx={{ width: 32, height: 32, backgroundColor: "#C4CDD5" }}>
            {user ? user.email.substring(0, 1).toUpperCase() : ""}
          </Avatar>
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={signOutNotifyer}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  )
}
